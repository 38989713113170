import React from 'react';
import { useState, useEffect, useContext } from 'react';
import firebase from "firebase/compat/app";

import { makeStyles } from '@material-ui/core/styles';
import { InputAdornment, TableCell, Checkbox, TableRow, TextField, Typography, Breadcrumbs, Button, Avatar } from '@material-ui/core';
import { Link, useParams } from "react-router-dom";
import Resizer from 'react-image-file-resizer';
import { useForm, Controller, useFieldArray } from "react-hook-form";

import Autocomplete from '@material-ui/lab/Autocomplete';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';

import { ShakiContext } from "../store/ShakiProvider";
import { UsersContext } from "../store/UsersProvider";
import { ShopsContext } from "../store/ShopsProvider";
import { UploadImage, GetImagePath, getGtin14 } from '.././Utils';
import { UpdateProduct } from '.././Store';


function Product() {

	const { getProductionSites } = useContext(ShopsContext);
	const { setOpenSnackBar, GetProduct, getRemoteConf, getArticlesByCategory } = useContext(ShakiContext);
	const { user, checkUserRights } = useContext(UsersContext);

	var { product_id } = useParams();

	const classes = useStyles();

	var requirements_options = ['Végétarien', 'Végan'];

	const [current_product, setCurrentProduct] = useState({});
	const [packaging_types, setPackagingTypes] = useState({});
	const [brands, setBrands] = useState({});
	const [articles, setArticles] = useState([]);
	const [production_sites, setProductionSites] = useState([]);
	const [packaging_cost, setPackagingCost] = useState(0);

	const { register, handleSubmit, setValue, errors, control, reset } = useForm({
		defaultValues: {
			packaging: {
			},
			requirements: []
		}
	});


	const { fields: fields_checklist, append: append_checklist, remove: remove_checklist } = useFieldArray({
		control,
		name: 'checklist'
	});


	useEffect(() => {
		setPackagingTypes(getRemoteConf('packaging_types'));
		setBrands(getRemoteConf('brands'));
	}, []);


	useEffect(() => {

		getArticlesByCategory('Emballage', (list) => {
			setArticles(list.sort((a, b) => a.name.localeCompare(b.name)));
		});

	}, []);


	useEffect(() => {

		getProductionSites((list) => {
			setProductionSites(list.sort((a, b) => a.name.localeCompare(b.name)));
		});

	}, []);


	useEffect(() => {

		if (articles.length === 0) return;
		if (production_sites.length === 0) return;

		GetProduct(product_id, (doc) => {

			var data = doc.data();
			data.id = doc.id;

			if (data.production_sites !== undefined) {
				var default_production_sites = [];

				for (var id of data.production_sites) {
					console.log(id);
					var site = production_sites.find((element) => element.id === id);
					console.log('site', site);
					default_production_sites.push(site);
				};

				console.log('default_production_sites', default_production_sites);

				data.production_sites = default_production_sites;
			};

			if (data.packaging !== undefined && data.packaging.articles !== undefined) {
				var default_articles = [];
				var cost = 0;

				for (var id of data.packaging.articles) {
					var article = articles.find((element) => element.id === id);
					default_articles.push(article);

					cost += lookupUnitCost(article);
				};

				data.packaging.articles = default_articles;
				setPackagingCost(cost);
			};

			reset(data);
			setCurrentProduct(data);
		});

	}, [product_id, articles, production_sites]);


	const lookupUnitCost = (article) => {

		var next = article.destock_unit_id;
		var arr = [];

		for (var i = 0; i < 10; i++) {
			var unit = article.units[next];
			if (unit.name === 'g' || unit.name === 'kg') continue
			arr.push(unit);
			if (unit.parent_unit_id === null) break;
			next = unit.parent_unit_id;
		};

		return arr.pop().cost
	};


	const getNextPictureId = () => {

		if (current_product.images === undefined) return 0;

		var last = 0;
		var a = Object.keys(current_product.images).sort();
		for (var i in a) {
			var value = parseInt(a[i]);
			if (isNaN(value)) continue;
			last = value + 1;
		}

		return last;
	};


	const fileChangedHandler = (event) => {

		var fileInput = false
		if (event.target.files[0]) {
			fileInput = true
		};

		if (fileInput) {
			Resizer.imageFileResizer(event.target.files[0], 1200, 1200, 'JPEG', 90, 0, (file) => {
				UploadImage('products', event.target.id, current_product.id, file, (path) => {
					setOpenSnackBar('Sauvegardé')
				});
			}, 'file');
		};
	};


	const onSubmit = (raw_data) => {

		var data = JSON.parse(JSON.stringify(raw_data));

		var packaging_articles = [];
		for (var i in data.packaging.articles) {
			packaging_articles.push(data.packaging.articles[i].id);
		};
		data.packaging.articles = packaging_articles;

		var production_sites = [];
		for (var i in data.production_sites) {
			production_sites.push(data.production_sites[i].id);
		};
		data.production_sites = production_sites;

		if (data.packaging.heat_up === null) data.packaging.heat_up = 0;
		if (data.requirements.length === 0) data.requirements = firebase.firestore.FieldValue.delete();

		if (data.organoleptics.taste === '') delete data.organoleptics.taste;
		if (data.organoleptics.appearance === '') delete data.organoleptics.appearance;
		if (data.organoleptics.color === '') delete data.organoleptics.color;
		if (data.organoleptics.smell === '') delete data.organoleptics.smell;
		if (Object.values(data.organoleptics).length === 0) data.organoleptics = firebase.firestore.FieldValue.delete();

		if (Object.values(data.nutrition).length === 0) delete data.nutrition;

		delete data.parent_sku;
		delete data.sku;

		if (current_product.parent_sku === undefined) {
			delete data.name;
		};

		UpdateProduct(current_product.id, data, user, () => {
			setOpenSnackBar(true);
		});
	};


	if (Object.keys(current_product).length === 0) return null;


	return (
		<div style={{ padding: 20 }}>

			<Breadcrumbs aria-label="breadcrumb" style={{ paddingBottom: 20 }}>
				<Link to='/'>Accueil</Link>
				<Link to='/products'>Produits</Link>
				<Typography>{current_product.name}</Typography>
			</Breadcrumbs>

			<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', paddingBottom: 40 }}>
				<h2>{current_product.name}</h2>
			</div>

			<form onSubmit={handleSubmit(onSubmit)}>
			<div style={{ width: 700 }}>

					<div style={{ marginBottom: 85 }}>

						<div style={{ marginBottom: 40 }}>

							<div style={{ display: 'flex', marginBottom: 10 }}>

								<FormControl className={classes.formControl} style={{ width: '50%' }}>
									<InputLabel htmlFor="packaging_type">Marque</InputLabel>
									<Select
										required
										disabled={current_product.parent_sku === undefined}
										inputRef={register()}
										native
										name='brand'
										inputProps={{
											name: 'brand',
											id: 'brand'
										}}
										InputLabelProps={{ shrink: true }}
									>
										<option value=''>---</option>
										{Object.entries(brands).sort((a, b) => { return a[1].name.substr(2) < b[1].name.substr(2) ? -1 : 1; }).map((row, index) => (
											<option key={row[0]} value={row[1].name}>{row[1].name}</option>
										))}
									</Select>
								</FormControl>

								<TextField
									required
									disabled={current_product.parent_sku === undefined}
									InputLabelProps={{ shrink: true }}
									inputRef={register({
										required: true,
									})}
									name='category'
									label='Categorie'
									style={{ width: '50%', marginLeft: 10 }}
									error={errors.category ? true : false}
								/>
							</div>

							<div style={{ display: 'flex', marginBottom: 10 }}>

								{current_product.parent_sku !== undefined &&
								<TextField
									disabled={true}
									label='Parent SKU'
									InputLabelProps={{ shrink: true }}
									value={current_product.parent_sku}
									style={{ width: '25%', marginRight: 10 }}
								/>
								}

								<TextField
									required
									disabled={true}
									InputLabelProps={{ shrink: true }}
									inputRef={register({
										required : true,
									})}
									id='sku'
									name='sku'
									label='SKU'
									style={{ width: '25%' }}
									error={errors.sku ? true : false}
								/>

								<TextField
									inputRef={register()}
									InputLabelProps={{ shrink: true }}
									id='ean13'
									name='ean13'
									label='EAN13'
									style={{ width: '25%', marginLeft: 10 }}
								/>

								<TextField
									disabled={true}
									InputLabelProps={{ shrink: true }}
									style={{ width: '25%', marginLeft: 10 }}
									label='GTIN14'
									value={getGtin14(current_product.ean13)}
								/>
							</div>

							{current_product.parent_name !== undefined &&
								<div style={{display: 'flex', marginBottom: 15}}>
									<TextField
										disabled={true}
										InputLabelProps={{ shrink: true }}
										id='parent_name'
										name='parent_name'
										label='Nom parent'
										value={current_product.parent_name}
										style={{ width: '100%' }}
									/>
								</div>
							}

							<div style={{display: 'flex', marginBottom: 15}}>
								<TextField
									required
									disabled={current_product.parent_sku === undefined}
									InputLabelProps={{ shrink: true }}
									inputRef={register({
										required : true
									})}
									id='name'
									name='name'
									label='Nom'
									style={{ width: '100%' }}
									error={errors.name ? true : false}
								/>
							</div>

							<div style={{display: 'flex', marginBottom: 15}}>
								<TextField
									InputLabelProps={{ shrink: true }}
									inputRef={register()}
									name='locales.en.name'
									label='Nom 🇬🇧'
									style={{ marginLeft: 20, width: '100%' }}
									error={errors.name ? true : false}
								/>
							</div>

							<div style={{ display: 'flex', marginBottom: 15 }}>
								<TextField
									inputRef={register()}
									InputLabelProps={{ shrink: true }}
									name='descriptive_name'
									label='Nom descriptif'
									multiline
									style={{ width: '100%' }}
								/>
							</div>

							<div style={{ display: 'flex', marginBottom: 15 }}>
								<TextField
									inputRef={register()}
									InputLabelProps={{ shrink: true }}
									name='description'
									label='Description'
									multiline
									style={{ width: '100%' }}
								/>
							</div>

							<div style={{ display: 'flex', marginBottom: 15 }}>
								<TextField
									InputLabelProps={{ shrink: true }}
									inputRef={register()}
									name='locales.en.description'
									label='Description 🇬🇧'
									multiline
									style={{ marginLeft: 20, width: '100%' }}
								/>
							</div>

							<div style={{ display: 'flex', marginBottom: 15 }}>
								<TextField
									inputRef={register()}
									InputLabelProps={{ shrink: true }}
									name='extra_info'
									label='Extra'
									style={{ width: '100%' }}
								/>
							</div>

						</div>

						<div style={{ display: 'flex', flexDirection: 'column', marginBottom: 40 }}>

							<Typography variant='h6' noWrap style={{ paddingBottom: 20 }}><b>Médias :</b></Typography>

							<div>
								<label htmlFor='main' style={{ textAlign: 'center' }}>
									<input id='main' type='file' onChange={fileChangedHandler} style={{ display : 'none' }}/>
									<Avatar src={GetImagePath(current_product.images, 'main')} style={{ width: 225, height: 150 }} variant='rounded' component='span'>
										P
									</Avatar>
									Packshot
								</label>

								<label htmlFor='ambiance' style={{ marginLeft: 20, textAlign: 'center' }}>
									<input id='ambiance' type='file' onChange={fileChangedHandler} style={{ display : 'none' }}/>
									<Avatar src={GetImagePath(current_product.images, 'ambiance')} style={{ width: 225, height: 150 }} variant='rounded' component='span'>
										A
									</Avatar>
									Ambiance
								</label>
							</div>

							<div>
								<label htmlFor='label_reference' style={{ textAlign: 'center' }}>
									<input id='label_reference' type='file' onChange={fileChangedHandler} style={{ display : 'none' }}/>
									<Avatar src={GetImagePath(current_product.images, 'label_reference')} style={{ width: 225, height: 150 }} variant='rounded' component='span'>
										E
									</Avatar>
									Reférence étiquette
								</label>

								<label htmlFor='packaging_reference' style={{ marginLeft: 20, textAlign: 'center' }}>
									<input id='packaging_reference' type='file' onChange={fileChangedHandler} style={{ display : 'none' }}/>
									<Avatar src={GetImagePath(current_product.images, 'packaging_reference')} style={{ width: 225, height: 150 }} variant='rounded' component='span'>
										P
									</Avatar>
									Réference packaging
								</label>
							</div>

						</div>

						<div style={{ marginBottom: 40 }}>

							<Typography variant='h6' noWrap style={{paddingBottom: 20}}><b>Packaging / Impression :</b></Typography>

							{packaging_cost === 'luna' && Object.values(packaging_types).length > 0 &&
							<div style={{ display: 'flex', marginBottom: 15 }}>
								<FormControl className={classes.formControl} style={{ width: '70%' }}>
									<InputLabel htmlFor="packaging_type">Conditionnement</InputLabel>
									<Select
										inputRef={register()}
										native
										inputProps={{
											name: 'packaging.type',
											id: 'packaging_type',
										}}
										InputLabelProps={{ shrink: true }}
									>
										<option value=''>---</option>
										{Object.entries(packaging_types).sort((a, b) => { return a[1].name < b[1].name? -1 : 1; }).map((row, index) => (
											<option key={row[0]} value={row[0]}>{row[1].name}</option>
										))}
									</Select>
								</FormControl>
							</div>
							}

							<div style={{ display: 'flex', marginBottom: 15 }}>
								<FormControl className={classes.formControl} style={{ width: '70%' }}>
									<InputLabel htmlFor="packaging_type">PCB de</InputLabel>
									<Select
										inputRef={register({
											setValueAs: (value: string) => parseFloat(value)
										})}
										native
										inputProps={{
											name: 'packaging.pcb',
										}}
										InputLabelProps={{ shrink: true }}
									>
										<option value='1'>1</option>
										<option value='2'>2</option>
										<option value='3'>3</option>
										<option value='4'>4</option>
										<option value='5'>5</option>
										<option value='6'>6</option>
										<option value='7'>7</option>
										<option value='8'>8</option>
									</Select>
								</FormControl>
							</div>

							{articles.length > 0 &&
								<div style={{ display: 'flex', marginBottom: 15 }}>

									<Controller
										render={props => (
											<Autocomplete
												{...props}

												style={{ width: '70%' }}
												multiple
												id='tags-standard'
												options={articles}
												getOptionLabel={(option) => option.name}
												getOptionSelected={(option, value) => option.id === value.id}

												onChange={(_, data) => props.onChange(data)}

												filterSelectedOptions={true}

												renderInput={(params) => (
													<TextField
														{...params}
														variant='standard'
														label=''
														placeholder="Choisissez un ou plusieurs emballages"
													/>
												)}
											/>
										)}
										name='packaging.articles'
										control={control}
									/>

								</div>
							}

							<div style={{ display: 'flex', marginBottom: 15 }}>
								<FormControl className={classes.formControl} style={{ width: '70%' }}>
									<InputLabel htmlFor="packaging_format">Format étiquette</InputLabel>
									<Select
										inputRef={register()}
										native
										inputProps={{
											name: 'packaging.format',
											id: 'packaging_format',
										}}
										InputLabelProps={{ shrink: true }}
									>
										<option value='none'>---</option>
										<option value='daifuku'>Daifuku</option>
										<option value='luna_135_60'>Luna 135x60</option>
										<option value='onigiri'>Onigiri</option>
										<option value='rectangle_108_50'>Rectangle 108x50</option>
										<option value='roll'>Roll</option>
										<option value='round_60'>Rond diam 60</option>
									</Select>
								</FormControl>
							</div>

							<div style={{ display: 'flex', marginBottom: 15 }}>
								<FormControl className={classes.formControl} style={{ width: '70%' }}>
									<InputLabel htmlFor="packaging_format">Format étiquette arrière</InputLabel>
									<Select
										inputRef={register()}
										native
										inputProps={{
											name: 'packaging.back_format',
										}}
										InputLabelProps={{ shrink: true }}
									>
										<option value='none'>---</option>
										<option value='onigiri'>Onigiri</option>
										<option value='foodles'>Foodles</option>
										<option value='onigiri_monoprix'>Onigiri Monoprix</option>
										<option value='onigiri_grandfrais'>Onigiri Grand Frais</option>
										<option value='onigiri_rond'>Onigiri Rond</option>
										<option value='square_60'>Carré 60x60</option>
										<option value='square_60_barcode'>Carré 60x60 avec code barre</option>
										<option value='55_80'>Rectangle 55x80</option>
										<option value='55_80_carrefour'>Rectangle 55x80 Carrefour</option>
										<option value='55_80_foodles'>Rectangle 55x80 Foodles</option>
										<option value='55_80_monoprix'>Rectangle 55x80 Monoprix</option>
										<option value='55_80_barcode'>Rectangle 55x80 avec code barre</option>
										<option value='50_100_barcode'>Rectangle 50x100 avec code barre</option>
										<option value='95_120'>Rectangle 95x120</option>
										<option value='rectangle_108_50'>Rectangle 108x50</option>
									</Select>
								</FormControl>
							</div>

							<div style={{ display: 'flex', marginBottom: 15 }}>
								<FormControl className={classes.formControl} style={{ width: '70%' }}>
									<InputLabel htmlFor="packaging_triman">Triman</InputLabel>
									<Select
										inputRef={register()}
										native
										inputProps={{
											name: 'packaging.triman',
										}}
										InputLabelProps={{ shrink: true }}
									>
										<option value='none'>---</option>
										<option value='triman_bowl'>Bol</option>
										<option value='triman_bowl_cup'>Bol + coupelle sauce</option>
										<option value='triman_bowl_cup_sachet'>Bol + coupelle sauce + sachet</option>
										<option value='triman_flowpack'>Flowpack</option>
										<option value='triman_flowpack_kraft'>Flowpack + étui kraft</option>
										<option value='triman_onigiri'>Onigiri</option>
										<option value='triman_club'>Club</option>
									</Select>
								</FormControl>
							</div>

							<div style={{display: 'flex', marginBottom: 15 }}>
								<TextField
									inputRef={register({
										setValueAs: (value: string) => parseFloat(value)
									})}
									InputLabelProps={{ shrink: true }}
									InputProps={{
										endAdornment : <InputAdornment position="end">s</InputAdornment>,
									}}
									name='packaging.heat_up'
									label='Temps de chauffe'
									style={{ width: 150 }}
								/>
							</div>

							<div style={{display: 'flex', marginBottom: 15 }}>

								<TextField
									inputRef={register()}
									name='packaging.name_line_1'
									InputLabelProps={{ shrink: true }}
									label='Ligne 1'
									style={{ width: '50%' }}
								/>

								<TextField
									inputRef={register()}
									name='packaging.name_line_2'
									InputLabelProps={{ shrink: true }}
									label='Ligne 2'
									style={{ width: '50%', marginLeft: 15 }}
								/>
							</div>

							<div style={{ display: 'flex', marginBottom: 15 }}>
								<TextField
									inputRef={register()}
									InputLabelProps={{ shrink: true }}
									name='packaging.original_version'
									label='Version originale'
									style={{ width: '50%' }}
								/>
							</div>

							<div style={{ marginBottom : 15 }}>

								<Controller
									defaultValue={false}
									name='packaging.spicy'
									control={control}
									color='default'
									render={props =>
										<FormControlLabel
										control={<Checkbox color='default' onChange={e => props.onChange(e.target.checked)} checked={props.value} />}
										label='Spicy'
										style={{ width: 170 }}
										/>
									}
								/>
							</div>
						</div>

						<div style={{ marginBottom: 40 }}>

							<Typography variant='h6' noWrap style={{ marginBottom: 20 }}>
								<b>Site de production :</b>
							</Typography>

							{production_sites.length > 0 &&
								<div style={{ display: 'flex', marginBottom: 15 }}>

									<Controller
										render={props => (
											<Autocomplete
												{...props}

												style={{ width: '100%' }}
												multiple
												id='tags-standard'
												options={production_sites}
												getOptionLabel={(option) => option.name}
												getOptionSelected={(option, value) => option.id === value.id}

												onChange={(_, data) => props.onChange(data)}

												filterSelectedOptions={true}

												renderInput={(params) => (
													<TextField
														{...params}
														variant='standard'
														label=''
														placeholder="Choisissez un ou plusieurs site de production"
													/>
												)}
											/>
										)}
										name='production_sites'
										control={control}
									/>

								</div>
							}

						</div>


						<div style={{ marginBottom: 40 }}>

							<Typography variant='h6' noWrap style={{ marginBottom: 20 }}>
								<b>R&D :</b>
							</Typography>

							<div style={{ display: 'flex', marginBottom: 15 }}>
								<TextField
									inputRef={register()}
									InputLabelProps={{ shrink: true }}
									name='origin'
									label='Origine et espèce'
									style={{ width: '100%' }}
								/>
							</div>

							<div style={{ display: 'flex', marginBottom: 15 }}>

								<Controller
									render={props => (
										<Autocomplete
											{...props}

											style={{ width: '100%',  marginTop: 15 }}
											multiple
											id='requirements-standard'
											options={requirements_options}
											getOptionLabel={(option) => option}
											getOptionSelected={(option, value) => option === value}

											onChange={(_, data) => props.onChange(data)}

											filterSelectedOptions={true}

											renderInput={(params) => (
												<TextField
													{...params}
													variant='standard'
													label=''
													placeholder="Exigences alimentaires"
												/>
											)}
										/>
									)}
									defaultValue={[]}
									name='requirements'
									control={control}
								/>

							</div>
						</div>

						<div style={{ marginBottom: 40 }}>

							<Typography variant='h6' noWrap style={{ marginBottom: 20 }}>
								<b>Caractéristiques organoleptiques :</b>
							</Typography>

							<div style={{display: 'flex', marginBottom: 15 }}>
								<TextField
									inputRef={register()}
									InputLabelProps={{ shrink: true }}
									name='organoleptics.appearance'
									label='Aspect'
									style={{ width: '100%' }}
								/>
							</div>

							<div style={{display: 'flex', marginBottom: 15 }}>
								<TextField
									inputRef={register()}
									InputLabelProps={{ shrink: true }}
									name='organoleptics.color'
									label='Couleur'
									style={{ width: '100%' }}
								/>
							</div>

							<div style={{display: 'flex', marginBottom: 15 }}>
								<TextField
									inputRef={register()}
									InputLabelProps={{ shrink: true }}
									name='organoleptics.taste'
									label='Goût'
									style={{ width: '100%' }}
								/>
							</div>

							<div style={{display: 'flex', marginBottom: 15 }}>
								<TextField
									inputRef={register()}
									InputLabelProps={{ shrink: true }}
									name='organoleptics.smell'
									label='Odeur'
									style={{ width: '100%' }}
								/>
							</div>
						</div>

						<div style={{ marginBottom: 20 }}>
							<Typography variant='h6' noWrap style={{ marginBottom: 5 }}><b>Ingrédients</b></Typography>
							{current_product.ingredients}
						</div>

						<div style={{ display: 'flex', marginBottom: 15 }}>
							<TextField
								inputRef={register()}
								InputLabelProps={{ shrink: true }}
								name='simplified_ingredients'
								label="Liste d'ingrédients simplifiée"
								multiline
								style={{ width: '100%' }}
							/>
						</div>

						{current_product.allergens !== undefined &&
						<div style={{ marginBottom: 20 }}>
							<Typography variant='h6' noWrap style={{ marginBottom: 5 }}><b>Allergènes</b></Typography>
							{current_product.allergens.join(', ')}
						</div>
						}

						{current_product.allergens_traces !== undefined &&
						<div style={{ marginBottom: 20 }}>
							<Typography variant='h6' noWrap style={{ marginBottom: 5 }}><b>Traces d'allergènes</b></Typography>
							{current_product.allergens_traces.join(', ')}
						</div>
						}

						{current_product.nutrition === 'lala' &&
						<div style={{ }}>
							<Typography variant='h6' noWrap style={{ marginBottom: 5 }}><b>Valeurs nutritionnelles</b></Typography>
							<p style={{ marginBottom: 0 }}>Energie : {current_product.nutrition.kcal}kcal / {current_product.nutrition.kj}kj</p>
							<p style={{ marginBottom: 0 }}>Protéines : {current_product.nutrition.proteins}g</p>
							<p style={{ marginBottom: 0 }}>Lipides : {current_product.nutrition.fat}g</p>
							<p style={{ marginBottom: 0 }}>Dont graisses saturées : {current_product.nutrition.saturated_fat}g</p>
							<p style={{ marginBottom: 0 }}>Glucides : {current_product.nutrition.carbs}g</p>
							<p style={{ marginBottom: 0 }}>Dont sucre : {current_product.nutrition.sugar}g</p>
							<p style={{ marginBottom: 0 }}>Fibres : {current_product.nutrition.fibers}g</p>
							<p style={{ marginBottom: 0 }}>Sel : {current_product.nutrition.salt}g</p>
						</div>
						}

						<Controller
							defaultValue={false}
							name='override_melba'
							control={control}
							color='default'
							render={props =>
								<FormControlLabel
								control={<Checkbox color='default' onChange={(e) => { props.onChange(e.target.checked); current_product.override_melba = e.target.checked; setCurrentProduct({...current_product}); return; }} checked={props.value} />}
								label='Ecraser les valeurs Melba'
								style={{ width: '50%' }}
								/>
							}
						/>

						<div style={{ display: 'flex', flexDirection: 'column', marginBottom: 20 }}>

							<Typography variant='h6' noWrap style={{ marginBottom: 5 }}><b>Valeurs nutritionnelles</b></Typography>

							<TextField
								disabled={current_product.override_melba !== true}
								inputRef={register({
									setValueAs: (value: string) => parseFloat(value)
								})}
								InputLabelProps={{ shrink: true }}
								InputProps={{
									endAdornment : <InputAdornment position="end">kcal</InputAdornment>,
								}}
								name='nutrition.kcal'
								label='Kcal'
								style={{ width: '30%' }}
							/>

							<TextField
								disabled={current_product.override_melba !== true}
								inputRef={register({
									setValueAs: (value: string) => parseFloat(value)
								})}
								InputLabelProps={{ shrink: true }}
								InputProps={{
									endAdornment : <InputAdornment position="end">kj</InputAdornment>,
								}}
								name='nutrition.kj'
								label='Energie'
								style={{ width: '30%' }}
							/>

							<TextField
								disabled={current_product.override_melba !== true}
								inputRef={register({
									setValueAs: (value: string) => parseFloat(value)
								})}
								InputLabelProps={{ shrink: true }}
								InputProps={{
									endAdornment : <InputAdornment position="end">g</InputAdornment>,
								}}
								name='nutrition.proteins'
								label='Protéines'
								style={{ width: '30%' }}
							/>

							<TextField
								disabled={current_product.override_melba !== true}
								inputRef={register({
									setValueAs: (value: string) => parseFloat(value)
								})}
								InputLabelProps={{ shrink: true }}
								InputProps={{
									endAdornment : <InputAdornment position="end">g</InputAdornment>,
								}}
								name='nutrition.fat'
								label='Lipides'
								style={{ width: '30%' }}
							/>

							<TextField
								disabled={current_product.override_melba !== true}
								inputRef={register({
									setValueAs: (value: string) => parseFloat(value)
								})}
								InputLabelProps={{ shrink: true }}
								InputProps={{
									endAdornment : <InputAdornment position="end">g</InputAdornment>,
								}}
								name='nutrition.saturated_fat'
								label='Dont graisses saturées'
								style={{ width: '30%' }}
							/>

							<TextField
								disabled={current_product.override_melba !== true}
								inputRef={register({
									setValueAs: (value: string) => parseFloat(value)
								})}
								InputLabelProps={{ shrink: true }}
								InputProps={{
									endAdornment : <InputAdornment position="end">g</InputAdornment>,
								}}
								name='nutrition.carbs'
								label='Glucides'
								style={{ width: '30%' }}
							/>

							<TextField
								disabled={current_product.override_melba !== true}
								inputRef={register({
									setValueAs: (value: string) => parseFloat(value)
								})}
								InputLabelProps={{ shrink: true }}
								InputProps={{
									endAdornment : <InputAdornment position="end">g</InputAdornment>,
								}}
								name='nutrition.sugar'
								label='Dont sucre'
								style={{ width: '30%' }}
							/>

							<TextField
								disabled={current_product.override_melba !== true}
								inputRef={register({
									setValueAs: (value: string) => parseFloat(value)
								})}
								InputLabelProps={{ shrink: true }}
								InputProps={{
									endAdornment : <InputAdornment position="end">g</InputAdornment>,
								}}
								name='nutrition.fibers'
								label='Fibres'
								style={{ width: '30%' }}
							/>

							<TextField
								disabled={current_product.override_melba !== true}
								inputRef={register({
									setValueAs: (value: string) => parseFloat(value)
								})}
								InputLabelProps={{ shrink: true }}
								InputProps={{
									endAdornment : <InputAdornment position="end">g</InputAdornment>,
								}}
								name='nutrition.salt'
								label='Sel'
								style={{ width: '30%' }}
							/>

						</div>

						<div style={{ width: 600, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginBottom: 20 }}>

							<div>
								<Typography variant='h6' noWrap style={{ marginBottom: 5 }}><b>Poids</b></Typography>
								<TextField
									disabled={current_product.override_melba !== true}
									inputRef={register({
										setValueAs: (value: string) => parseFloat(value)
									})}
									InputLabelProps={{ shrink: true }}
									InputProps={{
										endAdornment : <InputAdornment position="end">g</InputAdornment>,
									}}
									name='weight'
									style={{ width: 100 }}
								/>
							</div>

							<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
								<Typography variant='h6' noWrap style={{ marginBottom: 5 }}><b>DLC</b></Typography>
								{current_product.dlc_days} jours
							</div>

							<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
								<Typography variant='h6' noWrap style={{ marginBottom: 5 }}><b>Food cost</b></Typography>
								{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR', minimumFractionDigits: 2, maximumFractionDigits: 3 }).format(current_product.food_cost)}
							</div>

							<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
								<Typography variant='h6' noWrap style={{ marginBottom: 5 }}><b>Packaging cost</b></Typography>
								{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR', minimumFractionDigits: 2, maximumFractionDigits: 3 }).format(packaging_cost)}
							</div>

							<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
								<Typography variant='h6' noWrap style={{ marginBottom: 5 }}><b>Total</b></Typography>
								{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR', minimumFractionDigits: 2, maximumFractionDigits: 3 }).format(current_product.food_cost + packaging_cost)}
							</div>

						</div>

					</div>

					<div style={{ position: 'fixed', bottom: 0, left: 200, width: '100%', backgroundColor: 'white', padding: 24, borderTop: 'solid rgba(0, 0, 0, 0.12) 1px' }}>
						<Button disabled={!checkUserRights('products_write')} variant='contained' color='primary' type='submit'>
							Enregistrer
						</Button>
					</div>


			</div>
			</form>
		</div>
	);
}

export default React.memo(Product);

const useStyles = makeStyles({
	table: {
		minWidth: 700,
	}
});
