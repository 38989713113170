import React from 'react';

import firebase from "firebase/compat/app";
import "firebase/compat/firestore"

import { useState, useContext, useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Breadcrumbs, Avatar, IconButton, TextField } from '@material-ui/core';
import { Link } from "react-router-dom";

import { useForm, Controller, useFieldArray } from "react-hook-form";

import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { Button } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

import { GetImagePath } from '.././Utils';

import { UsersContext } from "../store/UsersProvider";
import { ShopsContext } from "../store/ShopsProvider";

function UsersExternal() {

	const { users_hash, createExternalUser } = useContext(UsersContext);
	const { shops } = useContext(ShopsContext);

	const title = 'Utilisateurs externes'
	const classes = useStyles();

	const [users_categories, setUsersCategories] = useState({});
	const [filter, setFilter] = useState('');

	const [open_create, setOpenCreate] = useState(false);
	const [create_error, setCreateError] = useState(false);

	const { register, handleSubmit, setValue, errors, control, reset } = useForm({
		defaultValues: {}
	});

	useEffect(() => {

		var category_hash = {};

		for (let i in users_hash) {

			var user = users_hash[i];

			if (user.email === undefined) continue;

			var category = (user.employer !== undefined) ? user.employer : 'autres';
			user.field_sort = user.first_name + ' ' + user.last_name;

			if (category_hash[category] === undefined) category_hash[category] = [];
			category_hash[category].push(user);
		};

		setUsersCategories(category_hash);

	}, [users_hash]);


	const deleteUser = (id) => {
		//return firebase.firestore().collection('users').doc(id).delete();
	};


	const onSubmit = (data) => {

		data.email = data.email.trim().toLowerCase();

		createExternalUser(data.email, (response) => {
			setOpenCreate(false);
		}, (error) => {
			setCreateError(true);
		});

		return true;
	};


	const closeCreate = () => {
		setCreateError(false);
		setOpenCreate(false);
	};


	return (
		<div>

			<div style={{ padding: 20 }}>

				<Breadcrumbs aria-label="breadcrumb" style={{ paddingBottom: 20 }}>
					<Link to='/'>Accueil</Link>
					<Typography>{title}</Typography>
				</Breadcrumbs>

				<h2>{title}</h2>

				<div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
					<div />
					<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'end', justifyContent: 'space-between' }}>



						<div style={{ alignItems: 'center' }}>
							<TextField
								style={{ width: 200 }}
								placeholder="Filtrer les utilisateurs"
								onChange={(e) => setFilter(e.target.value)}
							/>
						</div>

						<div style={{ marginLeft: 20 }}>
							<Button onClick={() => setOpenCreate(true)} variant='outlined' color='default'>
								Nouvel utilisateur
							</Button>
						</div>
					</div>
				</div>
			</div>

			<TableContainer component={Paper} style={{ marginBottom: 20 }}>
				<Table className={classes.table} aria-label="customized table">
					<TableHead>
						<TableRow>
							<StyledTableCell style={{ width: '35%' }}>Prénom / Nom</StyledTableCell>
							<StyledTableCell style={{ width: '30%' }}>Email</StyledTableCell>
							<StyledTableCell style={{ width: '35%' }}>Points de vente</StyledTableCell>
						</TableRow>
					</TableHead>
				</Table>
			</TableContainer>

			{Object.entries(users_categories).map((category) => (

			<div key={category[0]}>

				<TableContainer component={Paper} style={{ marginBottom: 40 }}>
					<Table className={classes.table} aria-label="customized table">

						<TableBody>
							{category[1].filter((row) => {
								return (filter === ''
									|| (row.email !== undefined && row.email.toLowerCase().search(filter) !== -1)
									|| (row.last_name && row.last_name.toLowerCase().search(filter) !== -1))
							}).sort((a, b) => {
								if (a.field_sort < b.field_sort) return -1;
								if (a.field_sort > b.field_sort) return 1;
								return 0;
							}).map((row) => (

								<TableRow key={row.id} className={classes.tr + ' ' + ((row.disabled) ? classes.tr_disabled : '') }>

									<StyledTableCell style={{ width: '35%' }}>
										<Link to={'/users/' + row.id}>{row.first_name} <b>{row.last_name}</b></Link>
									</StyledTableCell>

									<StyledTableCell style={{ width: '30%' }}>
										{row.email}
									</StyledTableCell>

									<StyledTableCell style={{ width: '35%' }}>

										{shops !== null &&
										<>
											{row.shops_owner.map((shop_id, index) => (
												<div key={shop_id}>
													<Link key={shop_id} to={'/shops/' + shop_id}>{shops[shop_id].name}</Link><br />
												</div>
											))}
										</>
										}

									</StyledTableCell>

								</TableRow>
							))}
						</TableBody>

					</Table>
				</TableContainer>
			</div>
			))}


			<Dialog open={open_create} onClose={closeCreate} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
				<form onSubmit={handleSubmit(onSubmit)}>
					<DialogTitle id="alert-dialog-title">{'Créer un utilisateur'}</DialogTitle>
					<DialogContent>
						<DialogContentText id="alert-dialog-description">

							<TextField
								required
								inputRef={register({
									validate: (value) => {
										for (var v of value.split(',')) {
											if (v.trim().match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i) === null) return 'Email invalide';
										};
										return true
									}
								})}
								name='email'
								label='Email'
								style={{ width: 300, marginBottom: 20 }}
								error={errors.contact_email ? true : false}
							/>

							{create_error &&
								<Alert severity="error">Cet email est déjà utilisé.</Alert>
							}

						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={closeCreate} color='secondary' variant='outlined'>
							Annuler
						</Button>
						<Button color='primary' variant='outlined' type='submit'>
							Créer
						</Button>
					</DialogActions>
				</form>
			</Dialog>

		</div>
	);
}

export default React.memo(UsersExternal);

const StyledTableCell = withStyles((theme) => ({
	head : {
		backgroundColor : theme.palette.common.black,
		color : theme.palette.common.white,
	},
	body : {
		fontSize: 14,
	},
}))(TableCell);

const useStyles = makeStyles({
	table: {
		width: '100%'
		//minWidth: 700,
	},
	tr: {
		'&:nth-of-type(odd)': {
			backgroundColor: '#F5F5F5',
		}
	},
	tr_child: {
		'&:nth-of-type(2n+1)': {
			backgroundColor: '#E9E9E9',
		},
		'&:nth-of-type(2n)': {
			backgroundColor: '#E0E0E0',
		}
	},
	tr_disabled: {
		textDecorationLine: 'line-through'
	}
});
