import React, { useContext, useEffect, useState } from 'react';

import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { getApp } from 'firebase/app';
import { getFunctions, httpsCallable } from "firebase/functions";


import { DateTime } from "luxon";
import { Sort } from '.././Utils';

import { UsersContext } from '../store/UsersProvider';
import { ShopsContext } from '../store/ShopsProvider';

export const OrdersContext = React.createContext({});

function OrdersProvider({ children }) {

	const { checkUserRights } = useContext(UsersContext);
	const { shops, GetCurrentPriceList, GetCurrentRateCard } = useContext(ShopsContext);


	const createOrder = (key, shop_id, delivery_date, products, external_reference, onSuccess = () => { }, onError = () => { }) => {

		var f = getFunctions(getApp(), 'europe-west1')
		httpsCallable(f, 'orders-createOrder')({
			key: key,
			shop_id: shop_id,
			delivery_date: delivery_date,
			products: products,
			external_reference: external_reference
		}).then((response) => {
			onSuccess(response);
		}).catch((error) => {
			onError(error);
		});

		return true;
	};


	const duplicateOrder = (from_order_id, delivery_date, onSuccess = () => { }, onError = () => { }) => {

		var f = getFunctions(getApp(), 'europe-west1')
		httpsCallable(f, 'orders-duplicateOrder')({
			from_order_id: from_order_id,
			delivery_date: delivery_date
		}).then((response) => {
			onSuccess(response);
		}).catch((error) => {
			onError(error);
		});

		return true;
	};


	const deleteOrder = (order_id, onSuccess = () => { }, onError = () => { }) => {

		var f = getFunctions(getApp(), 'europe-west1')
		httpsCallable(f, 'orders-deleteOrder')({
			order_id: order_id
		}).then((response) => {
			onSuccess(response);
		}).catch((error) => {
			onError(error);
		});
	};


	const GetOrder = (id, callback) => {

		if (shops === null) return;

		return firebase.firestore().collection('shaki_orders').doc(id).onSnapshot((doc) => {

			var data = doc.data();
			data.shop = shops[data.shop_id];
			data.pricelist = GetCurrentPriceList(data.shop.pricelist_group_id, data.day);
			data.rate_card = GetCurrentRateCard(data.day);

			callback(data);
		});
	};


	const GetOrderRoutes = (id, callback) => {

		if (id === null) return;
		if (!checkUserRights()) return;

		return firebase.firestore().collection('routes').where('orders', 'array-contains', id).onSnapshot((querySnapshot) => {

			const list = [];

			if (querySnapshot === null) callback(list);

			querySnapshot.forEach(doc => {
				list.push(doc.data());
			});

			callback(list.sort((a, b) => Sort(a, b, 'step', 'asc')));
		});
	};


	const startRoute = (route_id) => {

		if (!checkUserRights()) return;

		return firebase.firestore().collection('routes').doc(route_id).update({
			state: 1
		});
	};


	const completeRoute = (route_id) => {

		if (!checkUserRights()) return;

		return firebase.firestore().collection('routes').doc(route_id).update({
			state: 2,
			completed_at: {
				time: DateTime.local().toSeconds()
			}
		});
	}


	const saveDelivered = (id, delivered) => {

		if (!checkUserRights()) return;

		return firebase.firestore().collection('shaki_orders').doc(id).update({
			delivered: delivered
		});
	}


	const saveCredited = (id, credited) => {

		if (!checkUserRights()) return;

		return firebase.firestore().collection('shaki_orders').doc(id).update({
			credited: credited
		});
	}


	const generateInvoice = (order_id) => {

		if (!checkUserRights()) return;

		return firebase.firestore().collection('invoices_queue').doc(order_id).set({
			type: 'invoice_order',
			year: DateTime.local().year,
			order_id: order_id
		});
	};


	const generateDeliveryFile = (route_id, order_id) => {

		if (!checkUserRights()) return;

		return firebase.firestore().collection('routes').doc(route_id).update({
			generate_delivery_file: order_id
		});
	};


	return(
		<OrdersContext.Provider value={{

			createOrder: createOrder,
			duplicateOrder: duplicateOrder,
			deleteOrder: deleteOrder,

			GetOrder: GetOrder,
			GetOrderRoutes: GetOrderRoutes,

			saveDelivered: saveDelivered,
			saveCredited: saveCredited,

			generateInvoice: generateInvoice,
			generateDeliveryFile: generateDeliveryFile,
			startRoute: startRoute,
			completeRoute: completeRoute
		}}>
			{children}
		</OrdersContext.Provider>
	);
}

export default OrdersProvider;
