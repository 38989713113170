import { withStyles, makeStyles } from '@material-ui/core/styles';

var button_margin = 20;

const colors = {
	weigh_background: '#FFF8EB',
	weigh_primary: '#3D2900',
	weigh_secondary: '#FFEBC2',

	temp_background: '#FFECEB',
	temp_primary: '#3D0500',
	temp_secondary: '#FFD8D6',

	placeholder_color: '#666',

	highlight_color: '#5F92BF'
};

const button = makeStyles({

	primary: {
		minWidth: 40,
		backgroundColor: '#FFED00',
		borderRadius: 10,
	},
	primary_text: {
		color: '#000000'
	},

	secondary: {
		minWidth: 40,
		backgroundColor: '#DDD',
		borderRadius: 10,
	},
	secondary_text: {
		color: '#000000'
	},


	danger: {
		minWidth: 40,
		backgroundColor: '#dc3545',
		borderRadius: 10,
	},
	danger_text: {
		color: '#FFF'
	}
});


const text = {

	h1: {
		fontSize: 22,
		marginBottom: 0
	},

	h2: {
		fontSize: 18,
		marginBottom: 0
	},

	h3: {
		fontSize: 16,
		marginBottom: 0
	},

	ellipsis : {
		overflow: 'hidden',
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
	},
};


export { button, text, colors }