import React from 'react';

import firebase from "firebase/compat/app";
import "firebase/compat/firestore"

import { useState, useContext, useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Breadcrumbs, Avatar, IconButton, TextField } from '@material-ui/core';
import { Link } from "react-router-dom";

import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

import { GetImagePath } from '.././Utils';

import { ShakiContext } from "../store/ShakiProvider";
import { UsersContext } from "../store/UsersProvider";

function Users() {

	const { getRemoteConf } = useContext(ShakiContext);
	const { users_hash } = useContext(UsersContext);

	const title = 'Utilisateurs';
	const classes = useStyles();

	const [users_categories, setUsersCategories] = useState({});
	const [user_jobs, setUserJobs] = useState({});
	const [delivery_operators, setDeliveryOperators] = useState({});

	const [filter, setFilter] = useState('');


	useEffect(() => {
		setUserJobs(getRemoteConf('user_jobs'));
		setDeliveryOperators(getRemoteConf('delivery_operators'));
	}, []);


	useEffect(() => {

		var category_hash = {};

		for (let i in users_hash) {

			var user = users_hash[i];

			var category = (user.employer !== undefined) ? user.employer : 'autres';
			user.field_sort = '';

			if (user.parent_user !== undefined && user.parent_user !== '') {
				user.field_sort = users_hash[user.parent_user].first_name + ' ' + users_hash[user.parent_user].last_name;
			};

			if (user.disabled) {
				user.field_sort += ((user.field_sort !== '') ? ' ' : '') + 'zzz';
			};

			user.field_sort += ((user.field_sort !== '') ? ' ' : '') + user.first_name + ' ' + user.last_name;

			if (category_hash[category] === undefined) category_hash[category] = [];
			category_hash[category].push(user);
		};

		setUsersCategories(category_hash);

	}, [users_hash]);


	const deleteUser = (id) => {
		//return firebase.firestore().collection('users').doc(id).delete();
	}

	return (
		<div>

			<div style={{ padding: 20 }}>

				<Breadcrumbs aria-label="breadcrumb" style={{ paddingBottom: 20 }}>
					<Link to='/'>Accueil</Link>
					<Typography>{title}</Typography>
				</Breadcrumbs>

				<h2>{title}</h2>

				<div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
					<div />
					<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'end', justifyContent: 'space-between' }}>
						<div style={{ alignItems: 'center' }}>
							<TextField
								style={{ width: 200 }}
								placeholder="Filtrer les utilisateurs"
								onChange={(e) => setFilter(e.target.value)}
							/>
						</div>
					</div>
				</div>
			</div>

			<TableContainer component={Paper} style={{ marginBottom: 20 }}>
				<Table className={classes.table} aria-label="customized table">

					<TableHead>
						<TableRow>
							<StyledTableCell style={{ width: '7%' }}></StyledTableCell>
							<StyledTableCell style={{ width: '18%' }}>Prénom / Nom</StyledTableCell>
							<StyledTableCell style={{ width: '15%' }}>Mobile</StyledTableCell>
							<StyledTableCell style={{ width: '15%' }}>Admin / Parent</StyledTableCell>
							<StyledTableCell style={{ width: '15%' }}>Rôle</StyledTableCell>
							<StyledTableCell style={{ width: '25%' }}>Droits</StyledTableCell>
						</TableRow>
					</TableHead>

				</Table>
			</TableContainer>

			{Object.entries(users_categories).map((category) => (

			<div key={category[0]}>

				<Typography variant="h6" noWrap style={{ marginLeft: 20, paddingBottom: 20 }}>
					<b>{category[0]}</b>
				</Typography>

				<TableContainer component={Paper} style={{ marginBottom: 40 }}>
					<Table className={classes.table} aria-label="customized table">

						<TableBody>
							{category[1].filter((row) => {
								return (filter === ''
									|| (row.first_name !== undefined && row.first_name.toLowerCase().search(filter) !== -1)
									|| (row.last_name && row.last_name.toLowerCase().search(filter) !== -1)
									|| (row.card_number !== undefined && row.card_number.search(filter) !== -1))
							}).sort((a, b) => {
								if (a.field_sort < b.field_sort) return -1;
								if (a.field_sort > b.field_sort) return 1;
								return 0;
							}).map((row) => (
								<TableRow key={row.id} className={ ((row.parent_user === undefined || row.parent_user === '') ? classes.tr : classes.tr_child) + ' ' + ((row.disabled) ? classes.tr_disabled : '') }>

									<StyledTableCell style={ (row.parent_user !== '' && row.parent_user !== undefined) ? { height: 80, width: '7%', padding: 0  } : { width: '7%' }}>

										{(row.parent_user !== '' && row.parent_user !== undefined) ?
											<div style={{ height: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'end', marginRight: 10 }}>
												<hr style={{ width: 1, height: '95%', backgroundColor: 'black', margin: 0, opacity: 0.3 }} />
												<hr style={{ width: 5, height: 1, backgroundColor: 'black', margin: 0, opacity: 0.3 }} />
												<Link to={'/users/' + row.id}>
													<Avatar src={GetImagePath(row.images, 'main')} style={(row.parent_user === undefined || row.parent_user === '') ? { width: 70, height: 70 } : { width: 40, height: 40 }}>{row.first_name !== undefined ? row.first_name.substr(0, 1) : 'A'}</Avatar>
												</Link>
											</div>
										:
											<Link to={'/users/' + row.id}>
												<Avatar src={GetImagePath(row.images, 'main')} style={(row.parent_user === undefined || row.parent_user === '') ? { width: 70, height: 70 } : { width: 40, height: 40 }}>{row.first_name !== undefined ? row.first_name.substr(0, 1) : 'A'}</Avatar>
											</Link>
										}

									</StyledTableCell>

									<StyledTableCell style={{ width: '18%' }}>
										<Link to={'/users/' + row.id}>{row.first_name} <b>{row.last_name}</b></Link>
										{row.provider !== undefined &&
											<>
											<br />
											<span style={{ color: '#666', padding: 3, borderRadius: 5, border: '0.5px solid black', fontSize: 10, marginRight: 2 }}>{row.provider}</span>
											</>
										}
									</StyledTableCell>

									<StyledTableCell style={{ width: '15%' }}>
										{row.phone_number}
									</StyledTableCell>

									<StyledTableCell style={{ width : '15%' }}>
										{(row.admin === true) ? 'oui' : ''}{(row.parent_user !== undefined && row.parent_user !== '') ? users_hash[row.parent_user].first_name + ' ' + users_hash[row.parent_user].last_name : ''}
									</StyledTableCell>

									<StyledTableCell style={{ width : '15%' }}>
										{(user_jobs[row.job] !== undefined) ? user_jobs[row.job] : ''}{(delivery_operators[row.job] !== undefined) ? 'Livreur ' + delivery_operators[row.job].name : ''}
									</StyledTableCell>

									<StyledTableCell  style={{ width : '25%' }}>
										{(row.roles !== undefined) ? Object.keys(row.roles).join(', ') : '---'}
									</StyledTableCell>

									{row.toto !== undefined &&
									<StyledTableCell style={{ width : '5%' }}>
										<IconButton onClick={(e) => {deleteUser(row.id); return true;}} variant="outlined" size="small">
											<DeleteOutlineIcon />
										</IconButton>
									</StyledTableCell>
									}

								</TableRow>
							))}
						</TableBody>

					</Table>
				</TableContainer>
			</div>
			))}
		</div>
	);
}

export default React.memo(Users);

const StyledTableCell = withStyles((theme) => ({
	head : {
		backgroundColor : theme.palette.common.black,
		color : theme.palette.common.white,
	},
	body : {
		fontSize: 14,
	},
}))(TableCell);

const useStyles = makeStyles({
	table: {
		width: '100%'
		//minWidth: 700,
	},
	tr: {
		'&:nth-of-type(odd)': {
			backgroundColor: '#F5F5F5',
		}
	},
	tr_child: {
		'&:nth-of-type(2n+1)': {
			backgroundColor: '#E9E9E9',
		},
		'&:nth-of-type(2n)': {
			backgroundColor: '#E0E0E0',
		}
	},
	tr_disabled: {
		textDecorationLine: 'line-through'
	}
});
