import React from 'react';
import { useState, useEffect, useContext } from 'react';

import firebase from "firebase/compat/app";
import "firebase/compat/firestore"

import { Link, useParams, useHistory } from "react-router-dom";
import { TextField, Typography, Breadcrumbs, Button, CircularProgress } from '@material-ui/core';
import { useForm, Controller, useFieldArray, useWatch } from "react-hook-form";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

import LuxonUtils from '@date-io/luxon';

import { ShakiContext } from "../store/ShakiProvider";
import { ShopsContext } from "../store/ShopsProvider";
import { UsersContext } from "../store/UsersProvider";
import { OrdersContext } from "../store/OrdersProvider";
import ProductForm from './ProductForm';

import { DateTime } from "luxon";

import { GetTotalPrices } from '.././Utils';


function CreateOrder() {

	const { products_by_sku, setOpenSnackBar, getRemoteConf } = useContext(ShakiContext);
	const { user, isAdmin, isShopOwner, checkUserRights } = useContext(UsersContext);
	const { price_list, GetCurrentPriceList, GetCurrentRateCard, GetShop } = useContext(ShopsContext);
	const { createOrder } = useContext(OrdersContext);

	var { order_id, shop_id } = useParams();
	const action = order_id === undefined ? 'create' : 'update';

	var history = useHistory();

	const today = DateTime.local();

	const [items, setItems] = useState([]);

	const [eligible_delivery_days, setEligibleDeliveryDays] = useState(null);

	const [order, setOrder] = useState({
		products : {}
	});

	const [current_shop, setCurrentShop] = useState(null);
	const [current_price_list, setCurrentPricelist] = useState({});
	const [current_rate_card, setCurrentRateCard] = useState({});

	const [loading, setLoading] = useState(false);

	const { register, handleSubmit, control, reset, setValue} = useForm({
		defaultValues: {
			products: {}
		}
	});


	useEffect(() => {

		if (order_id !== undefined) return;
		if (shop_id === undefined) return;

		// Create case
		GetShop(shop_id, (doc) => {
			var data = doc.data();
			setCurrentShop(data);
		}, false);

	}, [order_id, shop_id]);


	useEffect(() => {

		if (shop_id !== undefined) return;
		if (order.shop_id === undefined) return;

		// Update case
		GetShop(order.shop_id, (doc) => {
			var data = doc.data();
			setCurrentShop(data);
		}, false);

	}, [shop_id, order]);


	useEffect(() => {

		if (eligible_delivery_days === null) return;
		if (current_shop === null) return;
		if (order_id !== undefined) return;


		var next_delivery_day = today;
		do {
			next_delivery_day = next_delivery_day.plus({ days: 1 });
		} while (displayDate(next_delivery_day));


		// Create case
		var order = {
			key: Math.random().toString(20).substr(2, 12),
			day: next_delivery_day,
			external_reference: '',
			shop_id: current_shop.id,
			products: {}
		};

		reset(order);
		setOrder(order);
		setValue('day', next_delivery_day);

	}, [order_id, current_shop, eligible_delivery_days]);


	useEffect(() => {

		if (order_id === undefined) return;

		return firebase.firestore().collection('shaki_orders').doc(order_id).get().then((doc) => {

			var order = doc.data();
			var d = DateTime.fromISO(order.day);
			order.day = d;

			reset(order);
			setOrder(order);
			setValue('day', d);
		});

	}, [order_id]);


	var delivery_day = useWatch({ control, name: 'day' })
	useEffect(() => {

		if (delivery_day === undefined) return;
		if (current_shop === null) return;
		if (current_shop.pricelist_group_id === undefined) return;
		if (Object.values(price_list).length === 0) return;

		console.log('useeffect2', delivery_day);

		setCurrentRateCard(GetCurrentRateCard(parseInt(delivery_day.toFormat('yyyyLLdd'))));
		setCurrentPricelist(GetCurrentPriceList(current_shop.pricelist_group_id, parseInt(delivery_day.toFormat('yyyyLLdd'))));

	}, [delivery_day, current_shop, price_list]);


	useEffect(() => {

		if (current_shop === null) return;
		if (current_shop.delivery_days === undefined) return;

		console.log('current_shop.delivery_days', current_shop.delivery_days);
		var d = [];
		for (var i in current_shop.delivery_days) {
			if (current_shop.delivery_days[i].enabled === false) continue;
			d.push(parseInt(i) + 1);
		};

		setEligibleDeliveryDays(d);

		/*return getRoutesConfs((docs) => {

			var d = [];
			docs.forEach((doc) => {

				var shift = doc.data();

				for (var i in shift.list) {

					if (current_shop.delivery_national_direct !== true && shift.list[i].shop_id === current_shop.id) {
						d.push(shift.day)
					};

					if (current_shop.delivery_national_direct === true && shift.list[i].shop_id === 'M3C26aarYXsZ4uEAKwoO') {
						d.push(shift.day + 1)
					};
				};
			});

			setEligibleDeliveryDays(d);
		});
		*/

	}, [current_shop]);


	const onSubmit = (data) => {

		for (var sku in data.products) {

			if (parseInt(data.products[sku]) === 0 || data.products[sku] === '') {
				delete data.products[sku];
				continue;
			};

			data.products[sku] = parseInt(data.products[sku]);
		};

		setLoading(true);

		createOrder(data.key, current_shop.id, parseInt(delivery_day.toFormat('yyyyLLdd')), data.products, data.external_reference, (response) => {
			console.log('response', response);
			setLoading(false);
			history.push('/shops/' + current_shop.id);
			setOpenSnackBar('Sauvegardé');
		}, (error) => {
			setLoading(false);
			setOpenSnackBar('Erreur')
		});

		return true;
	};


	const displayDate = (date) => {
		if (eligible_delivery_days === null) return false;
		console.log('checkUserRights', checkUserRights('orders_create'), user);
		if (!checkUserRights('orders_create') && date.toFormat('yyyyLLdd') === today.toFormat('yyyyLLdd')) return true;
		if (!checkUserRights('orders_create') && date.toFormat('yyyyLLdd') === today.plus({ days: 1 }).toFormat('yyyyLLdd')) return true;
		return !eligible_delivery_days.includes(parseInt(date.toFormat('c')));
	};


	const getTotalProducts = (products) => {
		var total = 0;
		for (var sku in products) {
			total += parseInt(products[sku]);
		};
		return total;
	};


	const Brand = (row) => {
		return (
			<div style={{ backgroundColor: '#000', color: '#fff', padding: 5, paddingLeft: 10, marginTop: 20, width: 700, borderRadius: 10 }}>
				{row.category}
			</div>
		);
	};


	const Category = (row) => {
		return (
			<div style={{ borderBottom: '1px solid black', margin: 10, marginTop: 20, marginBottom: 15 }}>
				{row.category}
			</div>
		);
	};



	////////////////////
	// Calcul Pricing //
	////////////////////

	const product_fields = useWatch({
		control,
		name: 'products',
		defaultValue: {}
	});

	var computed_price = GetTotalPrices(current_rate_card, current_price_list, product_fields, 'object');
	var total_products = getTotalProducts(product_fields);


	useEffect(() => {

		if (Object.values(current_price_list).length === 0) return;
		if (Object.values(products_by_sku).length === 0) return;

		var recipes = {};

		for (var sku in current_price_list.prices) {

			if (current_price_list.prices[sku].enabled === undefined || current_price_list.prices[sku].enabled === false) continue;
			if (products_by_sku[sku] === undefined) continue;
			if (products_by_sku[sku].disabled === true) continue;

			var brand = (products_by_sku[sku].brand !== undefined) ? products_by_sku[sku].brand : 'Autres';
			if (recipes[brand] === undefined) recipes[brand] = {}

			var category = (products_by_sku[sku].category !== undefined) ? products_by_sku[sku].category : 'Autres';
			if (recipes[brand][category] === undefined) recipes[brand][category] = {};

			recipes[brand][category][sku] = current_price_list.prices[sku];
		};

		var sort_brand = Object.entries(recipes).sort((a, b) => {
			if (a[0].substring(2) < b[0].substring(2)) return -1;
			if (a[0].substring(2) > b[0].substring(2)) return 1;
			return 0;
		});

	 	var items = [];
	 	for (var i in sort_brand) {

			items.push(<Brand key={i} category={sort_brand[i][0]} />);

			var sort_category = Object.entries(sort_brand[i][1]).sort();
			for (var j in sort_category) {

				items.push(<Category key={i + '_' + j} category={sort_category[j][0]} />);

				var sort_sku = Object.entries(sort_category[j][1]).sort();
				for (var k in sort_sku) {

					items.push(<ProductForm key={i + '_' + j + '_' + k} register={register} control={control} order={order} {...sort_sku[k][1]} rate_card={current_rate_card} pricelist={current_price_list} />);
				};
			};
		};

		setItems(items);

	}, [current_price_list, products_by_sku]);

	console.log(current_shop)

	return (

		<form onSubmit={handleSubmit(onSubmit)}>

		<div style={{ width: 700, padding: 20 }}>

			<Breadcrumbs aria-label="breadcrumb">
				{isAdmin() &&
					<Link to='/'>Accueil</Link>
				}
				{current_shop !== null &&
					<Link to={'/shops/' + current_shop.id}>{current_shop.name}</Link>
				}
				{order !== null &&
					<Typography>{order.key}</Typography>
				}
			</Breadcrumbs>

			{action === 'create' &&
				<h2 style={{ paddingBottom: 40 }}>Nouvelle commande</h2>
			}

			{action === 'update' &&
				<h2 style={{ paddingBottom: 40 }}>Mise à jour</h2>
			}

			<div>

				<div style={{ paddingBottom: 40 }}>
					<Controller as={TextField}
						disabled={true}
						control={control}
						name='key'
						label='Réference Luna'
						defaultValue=''
						style={{ width: 200 }}
					/>

					<Controller as={TextField}
						control={control}
						name='external_reference'
						label='Réference externe'
						defaultValue=''
						style={{ width: 200, marginLeft: 15 }}
					/>
				</div>


				<div style={{ paddingBottom: 40 }}>

					<Typography variant="h6" noWrap><b>Quand souhaitez-vous être livré ?</b></Typography>

						<MuiPickersUtilsProvider utils={LuxonUtils} locale='fr'>
					<Controller
						as={
							<KeyboardDatePicker
								okLabel="Valider"
								clearLabel="Effacer"
								cancelLabel="Annuler"
								disableToolbar
								format='dd/MM/yyyy'
								margin='normal'
								label='Jour de livraison'
								onChange={(date) => {console.log(date)}}
								shouldDisableDate={(date) => displayDate(date)}
								disablePast={true}
								KeyboardButtonProps={{
									'aria-label': 'change date'
								}}
							/>
						}
						defaultValue={today}
						control={control}
						name='day'
						style={{ width: 200 }}
					/>
					</MuiPickersUtilsProvider>
				</div>

			</div>


			<div>
				<Typography variant="h6" noWrap><b>Produits</b></Typography>
				{items}
			</div>


			<div style={{ paddingBottom: 135 }}></div>


			<div id='total' style={{display : "flex", flexDirection: 'row', position: 'fixed', left: 0, bottom: 0, width: '100%', backgroundColor: '#ddd', padding : isAdmin() ? '10px 10px 10px 224px' : '20px 20px 20px 20px'}}>
				<div style={{ width : 700, height: 105, display : 'flex', justifyContent : 'space-between' }}>
					<div style={{display : "flex", flexDirection: 'column'}}>
						<div style={{display : 'flex', flexDirection : 'row', justifyContent : 'flex-end'}}>
							<div style={{width: 200}}><Typography variant="body2">Nombre de produits</Typography></div>
							<div style={{width: 100, textAlign : 'end'}}>
								<Typography variant="body2">{total_products}</Typography>
							</div>
						</div>
						<div style={{display : 'flex', flexDirection : 'row', justifyContent : 'flex-end'}}>
							<div style={{width: 200}}><Typography variant="body2">Total HT</Typography></div>
							<div style={{width: 100, textAlign : 'end'}}>
								<Typography variant="body2">
									{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(computed_price.total)}
								</Typography>
							</div>
						</div>
						<div style={{display : 'flex', flexDirection : 'row', justifyContent : 'flex-end'}}>
							<div style={{width: 200}}><Typography variant="body2">Livraison (franco {new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(computed_price.franco)})</Typography></div>
							<div style={{width: 100, textAlign : 'end'}}>
								<Typography variant="body2">
									{computed_price.delivery_fee > 0 ? new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(computed_price.delivery_fee) : 'offert'}
								</Typography>
							</div>
						</div>
						<div style={{display : 'flex', flexDirection : 'row', justifyContent : 'flex-end'}}>
							<div style={{width: 200}}><Typography variant="body2">Total TVA</Typography></div>
							<div style={{width: 100, textAlign : 'end'}}>
								<Typography variant="body2">
									{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(computed_price.tva)}
								</Typography>
							</div>
						</div>

						<div style={{display : 'flex', flexDirection : 'row', justifyContent : 'flex-end'}}>
							<div style={{width: 200}}><Typography variant="body1"><b>Net à payer</b></Typography></div>
							<div style={{width: 100, textAlign : 'end'}}>
								<Typography variant="body1">
									<b>{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(computed_price.ttc)}</b>
								</Typography>
							</div>
						</div>
					</div>
					<div style={{display: 'flex', alignItems : 'center'}}>
						{loading ?
							<CircularProgress size={20} />
							:
							<Button type='submit' variant='contained' color='primary' disabled={total_products === 0}>
								{action === 'create' ? 'Commander' : 'Mettre à jour la commande'}
							</Button>
						}
					</div>
				</div>
			</div>
		</div>
		</form>
	);
}

export default React.memo(CreateOrder);
