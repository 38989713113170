import React from 'react';
import { useState, useEffect, useContext } from 'react';

import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Breadcrumbs, Button, TextField } from '@material-ui/core';
import { Link, useParams, useHistory } from "react-router-dom";
import FileCopyIcon from '@material-ui/icons/FileCopy';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy, faFileCsv, faList, faFile, faCircleDot, faPrint } from '@fortawesome/free-solid-svg-icons'

import { ShopsContext } from "../store/ShopsProvider";
import { UsersContext } from "../store/UsersProvider";

import { DownloadFile, FetchFile } from '.././Utils';

import { DateTime } from "luxon";

function Prices() {

	const { user_token, checkUserRights } = useContext(UsersContext);
	const { economic_models, price_list, price_list_groups, CreatePricelistGroup, DuplicatePricelist, GetCurrentPriceList } = useContext(ShopsContext);

	const [filter, setFilter] = useState('');

	const [dialog_new_opened, setDialogNewOpened] = useState(false);
	const [dialog_new_content, setDialogNewContent] = useState({});

	const [open_print, setOpenPrint] = useState(false);
	const [print_obj, setPrintObj] = useState(null);

	const title = 'Liste de Prix';
	const classes = useStyles();
	const history = useHistory();


	// New Price List Group

	const newPriceListGroup = () => {

		setDialogNewOpened(false);

		CreatePricelistGroup(dialog_new_content.title, (id) => {
			history.push('/prices/' + id);
		});

		return true;
	};


	const closeNew = () => {
		setDialogNewOpened(false);
	};


	const getCSV = (id) => {
		var uri = 'https://europe-west1-shakishaki-243509.cloudfunctions.net/products-getPricelistCSV?fields=brand,sku,ean13,name,weight,ingredients,nutrition.kcal,nutrition.kj,nutrition.proteins,nutrition.carbs,nutrition.sugar,nutrition.fat,nutrition.saturated_fat,nutrition.salt,allergens,dlc_days,price,discount&pricelist_id=' + id;
		return FetchFile(user_token, uri, 'products');
	};


	// Print

	const closePrint = () => {
		setOpenPrint(false);
	};


	const getPrintCsv = (id, format) => {
		var uri = 'https://europe-west1-shakishaki-243509.cloudfunctions.net/products-getPricelistCSV?fields=sku,brand,ean13,name,weight,packaging.format,packaging.heat_up,packaging.spicy,packaging.original_version,packaging.name_line_1,packaging.name_line_2,nutrition.total_kcal,description,extra_info,total_kcal,price,retail_price,kilo_price&pricelist_id=' + id + '&packaging_format=' + format;
		return FetchFile(user_token, uri, 'print_' + format);
	};


	return (
		<div>

			<div style={{ padding: 20 }}>

				<Breadcrumbs aria-label="breadcrumb">
					<Link to='/'>Dashboard</Link>
					<Typography>{title}</Typography>
				</Breadcrumbs>

				<h2>{title}</h2>

				<div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
					<div />
					<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'end' }}>
						<TextField
							style={{ marginRight: 20, width: 200 }}
							placeholder="Filtrer les listes de prix"
							onChange={(e) => setFilter(e.target.value)}
						/>

						<Button onClick={() => setDialogNewOpened(true)} variant='outlined' color='default'>
							Nouvelle Liste
						</Button>
					</div>
				</div>
			</div>

			<Table className={classes.table} style={{ marginBottom: 20 }} aria-label="customized table">
				<TableHead>
					<TableRow>
						<StyledTableCell style={{ width: '35%' }}>Nom</StyledTableCell>
						<StyledTableCell style={{ width: '20%' }}>Date d'application</StyledTableCell>
						<StyledTableCell style={{ width: '15%' }}>Modèle</StyledTableCell>
						<StyledTableCell style={{ width: '10%', textAlign: 'right' }}>Total produits</StyledTableCell>
						<StyledTableCell style={{ width: '20%', textAlign: 'right' }}>Actions</StyledTableCell>
					</TableRow>
				</TableHead>
			</Table>

			{Object.values(price_list_groups).map((group, index) => (
				<div key={group.id} style={{ marginBottom: 20 }}>

					<h6 style={{ marginLeft: 12 }}>
						{group.name}
					</h6>

					<TableContainer component={Paper}>
						<Table className={classes.table}>
							<TableBody>

								{Object.values(price_list).sort((a, b) => {
									if (a.field_sort < b.field_sort) return -1;
									if (a.field_sort > b.field_sort) return 1;
									return 0;
								}).filter((row) => {
									if (row.pricelist_group_id === undefined || row.pricelist_group_id === '' || row.pricelist_group_id !== group.id) return false;
									return (filter === '' || row.name.toLowerCase().search(filter.toLowerCase()) !== -1);
								}).map((row) => (

									<TableRow key={row.id} className={row.rate_card === undefined ? classes.tr : classes.tr_rate_card}>

										<TableCell style={{ width: '35%' }} className={row.current ? classes.td_current : classes.td}>
											<Link to={'/prices/' + row.id}>
												{(row.current !== undefined && row.current) ? <FontAwesomeIcon icon={faCircleDot} size='sm' color='green'/> : '' } {row.name}
											</Link>
										</TableCell>

										<TableCell style={{ width: '20%' }} className={row.current ? classes.td_current : classes.td}>
											{DateTime.fromISO(row.date_start).toFormat('dd LLL yyyy')}
										</TableCell>

										<TableCell style={{ width: '15%' }} className={row.current ? classes.td_current : classes.td}>
											{economic_models[row.type]}
										</TableCell>

										<TableCell style={{ width: '10%', textAlign: 'right' }} className={row.current ? classes.td_current : classes.td}>
											{Object.values(row.prices).filter((count) => {return count.enabled === true}).length}
										</TableCell>

										<TableCell style={{ width: '20%', textAlign: 'right' }} className={row.current ? classes.td_current : classes.td}>

											<Button onClick={(e) => {
												setPrintObj(row);
												setOpenPrint(true);
											}} color='primary' size='small' style={{ minWidth: 30 }}>
												<FontAwesomeIcon icon={faPrint} size='xl' title='Imprimer'/>
											</Button>

											<Button onClick={(e) => getCSV(row.id)} color='primary' size='small' style={{ minWidth: 30 }}>
												<FontAwesomeIcon icon={faFileCsv} size='xl' title='Fichier CSV'/>
											</Button>

											<Button onClick={(e) => DownloadFile('pricelist/mercuriale_' + row.id + '.pdf')} color='primary' size='small' style={{ minWidth: 30 }}>
												<FontAwesomeIcon icon={faList} size='xl' title='Mercuriale'/>
											</Button>

											<Button onClick={(e) => DownloadFile('pricelist/fiches_' + row.id + '.pdf')} color='primary' size='small' style={{ minWidth: 30 }}>
												<FontAwesomeIcon icon={faFile} size='xl' title='Fiches techniques'/>
											</Button>

											<Button disabled={!checkUserRights('pricelists_write')} onClick={(e) => DuplicatePricelist(row)} color='primary' size='small' style={{ minWidth: 30 }}>
												<FontAwesomeIcon icon={faCopy} size="xl" />
											</Button>

										</TableCell>
									</TableRow>
								))}

							</TableBody>
						</Table>
					</TableContainer>
				</div>
			))}


			<Table className={classes.table}>
				<TableBody>
					{Object.values(price_list).filter((row) => {
						if (row.pricelist_group_id !== undefined) return false;
						return (filter === '' || row.name.toLowerCase().search(filter.toLowerCase()) !== -1);
					}).map((row) => (
					<StyledTableRow key={row.id}>
						<StyledTableCell component="th" scope="row"><Link to={'/prices/' + row.id}>{row.name}</Link></StyledTableCell>
						<StyledTableCell>{Object.values(row.prices).filter((count) => {return count.enabled === true}).length}</StyledTableCell>
					</StyledTableRow>
					))}
				</TableBody>
			</Table>


			<Dialog open={dialog_new_opened} onClose={closeNew}>
				<DialogTitle>{'Nouvelle liste de prix'}</DialogTitle>
				<DialogContent>
					<div>
						<TextField
							id="title"
							name='title'
							label='Label'
							onChange={(e) => {
								const { name, value } = e.target;
								dialog_new_content[name] = value;
								setDialogNewContent({ ...dialog_new_content });
							}}
							style={{ width: 200 }}
						/>
					</div>
				</DialogContent>
				<DialogActions>
					<Button onClick={closeNew} color="primary">
						Annuler
					</Button>
					<Button onClick={newPriceListGroup} color='primary' autoFocus>
						Confirmer
					</Button>
				</DialogActions>
			</Dialog>


			<Dialog open={open_print} onClose={closePrint}>
				<DialogTitle>{'Imprimer'}</DialogTitle>
				<DialogContent>
					<DialogContentText>
						<div style={{ display: 'flex', flexDirection: 'column' }}>
							<Button onClick={(e) => getPrintCsv(print_obj.id, 'rectangle_108_50')} color='primary' variant='outlined' size='small' style={{ minWidth: 30, marginBottom: 10 }}>
								Format rectangle 108x50
							</Button>
							<Button onClick={(e) => getPrintCsv(print_obj.id, 'round_60')} color='primary' variant='outlined' size='small' style={{ minWidth: 30 }}>
								Format rond 60
							</Button>
						</div>
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={(e) => {
						closePrint();
					}} color='primary' autoFocus>
						Fermer
					</Button>
				</DialogActions>
			</Dialog>

		</div>
	);
};

export default React.memo(Prices);

const StyledTableCell = withStyles((theme) => ({
	head : {
		backgroundColor : theme.palette.common.black,
		color : theme.palette.common.white,
	},
	body : {
		fontSize: 14,
	},
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
	root : {
		'&:nth-of-type(odd)': {
			backgroundColor: theme.palette.action.hover,
		},
	}
}))(TableRow);

const useStyles = makeStyles({
	table: {
		minWidth: 700,
	},
	tr: {
		'&:nth-of-type(odd)': {
			backgroundColor: '#F5F5F5',
		}
	},
	tr_rate_card: {
		'&:nth-of-type(2n+1)': {
			backgroundColor: '#EDF0E5',
		},
		'&:nth-of-type(2n)': {
			backgroundColor: '#DBE1CB',
		}
	},
	td: {
		padding: 12,
		paddingTop: 5,
		paddingBottom: 5,
	},
	td_current: {
		padding: 12,
	},
});
