import React from 'react';
import { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import { InputAdornment, Checkbox, Paper, TextField, Typography, Breadcrumbs, Button, Avatar, Tabs, Tab } from '@material-ui/core';
import { Link, useParams, useHistory } from "react-router-dom";

import Resizer from 'react-image-file-resizer';
import { useForm, Controller } from "react-hook-form";

import Autocomplete from '@material-ui/lab/Autocomplete';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';


import { ShakiContext } from "../store/ShakiProvider";
import { UsersContext } from '../store/UsersProvider';
import { ShopsContext } from "../store/ShopsProvider";
import { ClientsContext } from "../store/ClientsProvider";

import Orders from './Orders';
import Sales from './Sales';
import ProductionTemplate from './ProductionTemplate';
import Invoices from './Invoices';

import { WeekDays, UploadImage, GetImagePath } from '.././Utils';
import { CreateShop, UpdateShop } from '.././Store';


function Shop() {

	const { setOpenSnackBar, getRemoteConf } = useContext(ShakiContext);
	const { user, checkUserRights, isShopOwner, isAdmin } = useContext(UsersContext);
	const { GetShop, shops, price_list, price_list_groups } = useContext(ShopsContext);
	const { getClients } = useContext(ClientsContext);

	var { shop_id } = useParams();

	var history = useHistory();
	const classes = useStyles();

	var action = shop_id === 'new' ? 'create' : 'update';
	var admin = !isShopOwner();
	const obsolete = true;

	const [clients, setClients] = useState([]);

	const [shops_by_key, setShopsByKey] = useState({});
	const [current_shop, setCurrentShop] = useState({});
	const [container_types, setContainerTypes] = useState({});
	const [tags, setTags] = useState([]);
	const [hasPlatform, setHasPlatform] = useState(false);

	var title = (current_shop.name !== undefined) ? current_shop.name : 'Nouveau';

	const { register, handleSubmit, setValue, errors, control, reset, watch } = useForm({
		defaultValues: current_shop
	});

	var display_national_direct = watch('delivery_national');


	useEffect(() => {
		if (isShopOwner() && user.shops_owner.includes(shop_id) === false) history.push('/shops/' + user.shops_owner[0]);
	}, [shop_id]);


	useEffect(() => {
		setContainerTypes(getRemoteConf('container_types'));
		setTags(getRemoteConf('tags'));
	}, []);


	useEffect(() => {

		if (tags.length === 0) return;

		if (shop_id === 'new') {
			setTabValue(1);
			return;
		};

		GetShop(shop_id, (doc) => {

			var data = doc.data();

			var default_tags = [];
			for (var i in data.tags) {
				default_tags.push(
					tags.find((element) => element.key === data.tags[i])
				);
			};
			data.tags = default_tags;

			reset(data);
			setHasPlatform(data.delivery_warehouse !== undefined && data.delivery_warehouse !== '')
			setCurrentShop(data);
		});

	}, [shop_id, tags]);


	useEffect(() => {

		if (shops === null) return;

		var hash = {};
		for (var i in shops) {
			hash[shops[i].key] = shops[i].id;
		};

		setShopsByKey(hash);

	}, [shops]);


	useEffect(() => {
		getClients((data) => {
			setClients(data);
		});
	}, []);


	/*
	if (shop_id === 'me') {
		shop_id = user.shop_owner;
		admin = false;
	}
	*/


	const fileChangedHandler = (event) => {

		if (event.target.files[0]) {

			Resizer.imageFileResizer(event.target.files[0], 1000, 1000, 'JPEG', 90, 0, (file) => {

				UploadImage('shops', 'main', current_shop.id, file, (path) => {
					setOpenSnackBar(true);
				});

			}, 'file');
		};
	};


	const [tab_value, setTabValue] = useState(0);
	const handleChange = (event, newValue) => {
		if (newValue === 4) return history.push('/shops/' + shop_id + '/planograms');
		setTabValue(newValue);
	};


	const onSubmit = (raw_data) => {

		var data = JSON.parse(JSON.stringify(raw_data));

		if (action === 'update') delete data.key;
		delete data.location;

		//data.invoicing.payment_method = parseInt(data.invoicing.payment_method);
		//data.invoicing.franco = parseInt(data.invoicing.franco);

		//if (isNaN(parseInt(data.ean13))) {
			//delete data.ean13;
		//} else {
			//data.ean13 = parseInt(data.ean13);
		//};

		for (var i in data.delivery_days) {
			if (data.delivery_days[i].enabled === undefined) data.delivery_days[i].enabled = false;
			if (!data.delivery_days[i].enabled) delete data.delivery_days[i].hour;
		};

		var new_tags = [];
		for (var i in data.tags) {
			new_tags.push(data.tags[i].key);
		};
		data.tags = new_tags;

		if (data.invoicing.holding !== undefined && data.invoicing.holding === '') delete data.invoicing.holding;

		if (action === 'create') {

			CreateShop(data, user, (id) => {
				setOpenSnackBar(true);
				history.push('/shops/' + id);
			});

		} else {

			UpdateShop(current_shop.id, data, user, () => {
				setOpenSnackBar(true);
			});
		};
	};


	if ((action === 'update' && Object.keys(current_shop).length === 0)) {
		return null;
	};

	if (Object.values(price_list).length === 0 || clients.length === 0) {
		return null;
	};


	return (
		<div>

			<div style={{ padding: 20 }}>

				{admin &&
				<Breadcrumbs aria-label="breadcrumb">
					<Link to='/'>Accueil</Link>
					<Link to='/shops'>Points de vente</Link>
					<Typography>{title}</Typography>
				</Breadcrumbs>
				}

				<div style={{ fontSize: 26, fontWeight: 'bold' }}>{title}</div>
				{isShopOwner() &&
					<div style={{ fontSize: 16 }}>{current_shop.address + ', ' + current_shop.cp + ' ' + current_shop.city}</div>
				}
			</div>

			{action === 'update' &&
			<Paper elevation={0}>
				<Tabs value={tab_value} onChange={handleChange} style={{ borderBottom: '1px solid #999999' }}>
					<Tab label='Commandes' />
					{isAdmin() &&
						<Tab label='Profil' />
					}
					{isAdmin() &&
						<Tab label='Factures' disabled={!checkUserRights('invoices') || hasPlatform} />
					}
					{isAdmin() &&
						<Tab label='Production' disabled={!checkUserRights('production_templates')} />
					}
					{isAdmin() &&
						<Tab label='Planogrammes' disabled={!checkUserRights('planograms')}/>
					}
				</Tabs>
			</Paper>
			}

			{(action === 'update') &&
			<div id='tab_0' role='tabpanel' hidden={tab_value !== 0}>
				<Orders include={true}/>
			</div>
			}

			{admin &&
			<div id='tab_1' role='tabpanel' hidden={tab_value !== 1} style={{ width: 700, marginBottom: 45, padding: 20 }}>

				<form onSubmit={handleSubmit(onSubmit)}>

					<div style={{ marginBottom: 40 }}>

						<Typography variant="h6" noWrap style={{ paddingBottom: 20 }}><b>Identité</b></Typography>

						<div>
							<Controller
								name='open'
								control={control}
								color='default'
								render={props =>
									<FormControlLabel
									control={<Checkbox color='default' onChange={e => props.onChange(e.target.checked)} checked={props.value} />}
									label='Ouvert'
									style={{ width: '20%' }}
									/>
								}
							/>
						</div>

						<div style={{ display: 'flex', alignItems: 'center' }}>

							<label htmlFor='picture'>
								<input id='picture' type='file' onChange={fileChangedHandler} style={{display : 'none'}}/>
								<Avatar src={GetImagePath(current_shop.images, 'main')} style={{ width: 100, height: 100 }} component='span' />
							</label>

							<TextField
								required
								inputRef={register({
									required: true,
									setValueAs: (value: string) => value.trim()
								})}
								id='name'
								name='name'
								label='Nom'
								style={{ width: '70%', marginLeft: 10 }}
								error={errors.name ? true : false}
							/>

							<TextField
								required
								disabled={action === 'update'}
								inputRef={register({
									required : true,
									validate : {
										positive: (value) => {
											value = value.trim().toLowerCase();
											return shops_by_key[value] === undefined || shops_by_key[value] === current_shop.id;
										}
									},
									setValueAs: (value: string) => value.trim()
								})}
								id='key'
								name='key'
								label='Key'
								style={{ width: '30%', marginLeft: 10 }}
								error={errors.key ? true : false}
							/>
						</div>

						<div>

							<Typography variant="h6" noWrap style={{ marginBottom: 20 }}>
								<b>EDI</b>
							</Typography>

							<div style={{ display: 'flex', marginBottom : 10 }}>

								<TextField
									inputRef={register({
										validate : {
											positive: (value) => {
												return value.length === 0 || value.length === 13;
											}
										},
										setValueAs: (value: string) => value.trim() === '' ? '' : parseInt(value.trim())
									})}
									name='ean13'
									label='EAN13 / GLN'
									style={{ width: '33%' }}
									error={errors.ean13 ? true : false}
								/>

								<TextField
									inputRef={register({
										validate : {
											positive: (value) => {
												return value.length === 0 || value.length === 13;
											}
										}
									})}
									name='ean13_ordered_by'
									label='GLN (Commandé par)'
									style={{ width: '33%', marginLeft: 10 }}
									error={errors.ean13_ordered_by ? true : false}
								/>

								<TextField
									inputRef={register({
										validate : {
											positive: (value) => {
												return value.length === 0 || value.length === 13;
											}
										}
									})}
									name='ean13_billed_by'
									label='GLN (Facturé à)'
									style={{ width: '33%', marginLeft: 10 }}
									error={errors.ean13_billed_by ? true : false}
								/>
							</div>

						</div>
					</div>

					<div style={{ marginBottom: 40 }}>

						<Typography variant="h6" noWrap style={{ marginBottom: 20 }}>
							<b>Client</b>
						</Typography>

						<div style={{ display: 'flex' }}>

							<FormControl className={classes.formControl} style={{ width: '100%' }}>
								<InputLabel htmlFor='client_id' shrink>Client</InputLabel>
								<Select
									inputRef={register()}
									native
									inputProps={{
										name: 'client_id',
										id: 'client_id',
									}}
								>
									<option value=''>---</option>
									{clients.sort((a, b) => a.legal_entity.localeCompare(b.legal_entity)).map((row, index) => (
										<option key={'client_id_' + index} value={row.id}>{row.legal_entity}</option>
									))}
								</Select>
							</FormControl>
						</div>
					</div>

					<div style={{ marginBottom: 40 }}>

						<Typography variant="h6" noWrap style={{ marginBottom: 20 }}>
							<b>Tags</b>
						</Typography>

						{tags.length > 0 &&
						<div style={{ display: 'flex', marginBottom : 10 }}>

							<Controller
								render={props => (
									<Autocomplete
										{...props}

										style={{ width: '100%' }}
										multiple
										id='tags-standard'
										options={tags}
										getOptionLabel={(option) => option.title}
										getOptionSelected={(option, value) => option.key === value.key}

										onChange={(_, data) => props.onChange(data)}

										filterSelectedOptions={true}

										renderInput={(params) => (
											<TextField
												{...params}
												variant='standard'
												label=''
												placeholder="Choisissez un ou plusieurs tags"
											/>
										)}
									/>
								)}
								name='tags'
								control={control}
								/>

						</div>
						}
					</div>

					<div style={{ marginBottom: 40 }}>

						<Typography variant='h6' noWrap style={{ marginBottom: 20 }}>
							<b>Contact</b>
						</Typography>

						<div style={{ display: 'flex', marginBottom: 15 }}>
							<TextField
								inputRef={register({
									setValueAs: (value: string) => value.trim()
								})}
								id='contact_name'
								name='contact_name'
								label='Identité du contact'
								style={{ width: '100%' }}
							/>
						</div>

						<div style={{ display: 'flex', marginBottom: 15 }}>
							<TextField
								required
								inputRef={register()}
								id='address'
								name='address'
								label='Adresse'
								style={{ width: '100%' }}
							/>
						</div>

						<div style={{ display: 'flex', marginBottom: 15 }}>
							<TextField
								required
								inputRef={register()}
								id='cp'
								name='cp'
								label='Code postal'
								style={{ width: '17%' }}
								error={errors.cp ? true : false}
							/>
							<TextField
								required
								inputRef={register()}
								id='city'
								name='city'
								label='Ville'
								style={{ width: '34%', marginLeft: 10 }}
							/>
							<TextField
								required
								inputRef={register()}
								id='country'
								name='country'
								label='Pays'
								style={{ width: '20%', marginLeft: 10 }}
							/>
							<TextField
								disabled
								inputRef={register()}
								InputLabelProps={{
									shrink: true,
								}}
								id='location_lat'
								name='location.lat'
								label='Latitude'
								style={{ width: '15%', marginLeft: 10 }}
							/>
							<TextField
								disabled
								inputRef={register()}
								InputLabelProps={{
									shrink: true,
								}}
								id='location_lng'
								name='location.lng'
								label='Longitude'
								style={{ width: '15%', marginLeft: 10 }}
							/>
						</div>

						<div style={{ display: 'flex', marginBottom: 15 }}>
							<TextField
								inputRef={register()}
								id='address_infos'
								name='address_infos'
								label='Informations complémentaires'
								style={{ width: '100%' }}
							/>
						</div>

						<div style={{ display: 'flex', marginBottom: 15 }}>
							<TextField
								inputRef={register({
									pattern : {
										value: /^(\+33)([123459]{1})([0-9]{2}){4}$/,
										message: "Numéro de téléphone invalide"
									}
								})}
								id='tel'
								name='tel'
								label='Téléphone'
								style={{ width: '50%' }}
								error={errors.tel ? true : false}
							/>
							<TextField
								inputRef={register({
									pattern : {
										value: /^(\+33)([67]{1})([-.: ]?[0-9]{2}){4}$/,
										message: "Numéro de téléphone invalide"
									}
								})}
								id='mobile'
								name='mobile'
								label='Téléphone portable'
								style={{ width: '50%', marginLeft: 10 }}
								error={errors.mobile ? true : false}
							/>
						</div>

						<div style={{ display: 'flex', marginBottom: 10 }}>
							<TextField
								required
								inputRef={register({
									validate: (value) => {
										for (var v of value.split(',')) {
											if (v.trim().match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i) === null) return 'Email invalide';
										};
										return true
									}
								})}
								id='contact_email'
								name='contact_email'
								label='Email'
								style={{ width: '100%' }}
								error={errors.contact_email ? true : false}
							/>
						</div>

					</div>

					<div style={{ marginBottom: 40, display: hasPlatform ? 'none' : 'block' }}>

						<Typography variant="h6" noWrap style={{ marginBottom: 20 }}>
							<b>Comptabilité</b>
						</Typography>

						<div style={{ display: 'flex', marginBottom: 15 }}>
							<TextField
								required
								inputRef={register()}
								name='contact_accounting.legal_entity'
								label='Entité juridique'
								style={{ width: '100%' }}
							/>
						</div>

						<div style={{ display: 'flex', marginBottom: 15 }}>
							<TextField
								required
								inputRef={register({
									pattern : {
										value: /^([0-9]{3}) ([0-9]{3}) ([0-9]{3})$/,
										message: "Siren invalide, format attendu 123 456 789"
									}
								})}
								name='contact_accounting.siren'
								label='Siren'
								style={{ width: '33%' }}
								error={(errors.contact_accounting !== undefined && errors.contact_accounting.siren) ? true : false}
							/>
							<TextField
								inputRef={register({
									pattern : {
										value: /^([0-9]{3}) ([0-9]{3}) ([0-9]{3}) ([0-9]{5})$/,
										message: "Siret invalide, format attendu 123 456 789 00000"
									}
								})}
								name='contact_accounting.siret'
								label='Siret'
								style={{ width: '33%', marginLeft: 10 }}
								error={(errors.contact_accounting !== undefined && errors.contact_accounting.siret) ? true : false}
							/>
							<TextField
								inputRef={register({
									pattern : {
										value: /^FR([0-9]{2})([0-9]{9})$/,
										message: "Siren invalide"
									}
								})}
								name='contact_accounting.vat'
								label='TVA'
								style={{ width: '33%', marginLeft: 10 }}
							/>
						</div>

						<div style={{ display: 'flex', marginBottom: 15 }}>
							<TextField
								required
								inputRef={register()}
								id='contact_accounting_first_name'
								name='contact_accounting.first_name'
								label='Prénom'
								style={{width: '50%'}}
							/>
							<TextField
								inputRef={register()}
								id='contact_accounting_last_name'
								name='contact_accounting.last_name'
								label='Nom'
								style={{ width: '50%', marginLeft: 10 }}
							/>
						</div>

						<div style={{ display: 'flex', marginBottom: 15 }}>
							<TextField
								required
								inputRef={register()}
								id='contact_accounting_address'
								name='contact_accounting.address'
								label='Adresse'
								style={{ width: '100%' }}
							/>
						</div>

						<div style={{ display: 'flex', marginBottom: 15 }}>

							<TextField
								required
								inputRef={register()}
								id='contact_accounting_city'
								name='contact_accounting.city'
								label='Ville'
								style={{ width: '60%' }}
							/>
							<TextField
								required
								inputRef={register()}
								id='contact_accounting_cp'
								name='contact_accounting.cp'
								label='Code postal'
								style={{ width: '15%', marginLeft: 10 }}
							/>
							<TextField
								required
								inputRef={register()}
								id='contact_accounting_country'
								name='contact_accounting.country'
								label='Pays'
								style={{ width: '25%', marginLeft: 10 }}
							/>
						</div>

						<div style={{ display: 'flex', marginBottom: 15 }}>
							<TextField
								inputRef={register({
									pattern : {
										value: /^(\+33)[1-2-3-4-5]([-.: ]?[0-9]{2}){4}$/,
										message: "Numéro de téléphone invalide"
									}
								})}
								id='contact_accounting_tel'
								name='contact_accounting.tel'
								label='Téléphone'
								style={{ width: '50%' }}
								error={(errors.contact_accounting !== undefined && errors.contact_accounting.tel) ? true : false}
							/>
							<TextField
								inputRef={register({
									pattern : {
										value: /^(\+33)[6-7]([-.: ]?[0-9]{2}){4}$/,
										message: "Numéro de téléphone invalide"
									}
								})}
								id='contact_accounting_mobile'
								name='contact_accounting.mobile'
								label='Téléphone portable'
								style={{ width: '50%', marginLeft : 10 }}
								error={(errors.contact_accounting !== undefined && errors.contact_accounting.mobile) ? true : false}
							/>

						</div>
						<div style={{ display: 'flex', marginBottom: 10 }}>
							<TextField
								required
								inputRef={register({
									validate: (value) => {
										for (var v of value.split(',')) {
											if (v.trim().match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i) === null) return 'Email invalide';
										};
										return true
									}
								})}
								id='contact_accounting_email'
								name='contact_accounting.email'
								label='Email'
								style={{width: '100%'}}
								error={(errors.contact_accounting !== undefined && errors.contact_accounting.email) ? true : false}
							/>
						</div>
						<div style={{ display: 'flex', marginBottom: 10 }}>
							<TextField
								required
								inputRef={register({
									validate: (value) => {
										for (var v of value.split(',')) {
											if (v.trim().match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i) === null) return 'Email invalide';
										};
										return true
									}
								})}
								id='contact_accounting_reminder_email'
								name='contact_accounting.reminder_email'
								label='Email Relance'
								style={{ width: '100%' }}
								error={ (errors.contact_accounting !== undefined && errors.contact_accounting.reminder_email) ? true : false }
							/>
						</div>

						<div style={{ display: 'flex', marginBottom: 15 }}>

							<FormControl className={classes.formControl} style={{ width: '30%', marginRight: 10 }}>
								<InputLabel htmlFor='bank_balance_group' shrink>Regroupement bancaire</InputLabel>
								<Select
									inputRef={register()}
									native
									inputProps={{
										name: 'bank_balance_group'
									}}
								>
									<option value=''></option>
									{Object.values(tags).filter((element) => element.type === 'client').sort((a, b) => a.title > b.title ? 1 : -1).map((row, index) => (
										<option key={'bank_balance_' + row.key} value={row.key}>{row.title}</option>
									))}
								</Select>
							</FormControl>

							<TextField
								inputRef={register({})}
								id='bank_informations_matching'
								name='bank_informations_matching'
								label='Informations bancaires'
								style={{ width: '70%' }}
							/>
						</div>
					</div>

					<div style={{ marginBottom: 40, display: hasPlatform ? 'none' : 'block' }}>

						<Typography variant="h6" noWrap style={{ marginBottom: 20 }}>
							<b>Horaires d'ouverture</b>
						</Typography>

						{WeekDays.map((day, index) => (
						<div key={'week_days_' + index} style={{ display: 'flex', marginBottom: 15 }}>

							<Controller
								name={`opening_days[${index}].open`}
								control={control}
								color='default'
								defaultValue={false}
								render={props =>
									<FormControlLabel
									control={<Checkbox color='default' onChange={e => props.onChange(e.target.checked)} checked={props.value} />}
									label={day}
									style={{ width:170 }}
									/>
								}
							/>

							<TextField
								inputRef={register()}
								name={`opening_days[${index}].start`}
								label="Ouverture"
								type="time"
								defaultValue="07:00"
								InputLabelProps={{
									shrink: true,
								}}
								inputProps={{
									step: 300
								}}
								style={{ width: 100, marginLeft: 10 }}
							/>

							<TextField
								inputRef={register()}
								name={`opening_days[${index}].end`}
								label="Fermeture"
								type="time"
								defaultValue="20:00"
								InputLabelProps={{
									shrink: true,
								}}
								inputProps={{
									step: 300
								}}
								style={{ width: 100, marginLeft: 10 }}
							/>

						</div>
						))}
					</div>

					<div style={{ marginBottom: 40 }}>

						<Typography variant="h6" noWrap style={{ marginBottom: 20 }}>
							<b>Fourniture de meubles</b>
						</Typography>

						<div>
							<FormControl className={classes.formControl} style={{ width: 350, marginBottom: 15 }}>
								<InputLabel htmlFor='fridge_provisionning' shrink>Mise à disposition d'un meuble frigorifique</InputLabel>
								<Select
									inputRef={register()}
									native
									inputProps={{
										name: 'fridge_provisionning'
									}}
								>
									<option value=''>Aucun</option>
									<option value='tray'>Bac</option>
									<option value='shelf'>Vitrine</option>
								</Select>
							</FormControl>
						</div>
					</div>

					{admin &&
					<div style={{ marginBottom: 40 }}>

						<Typography variant="h6" noWrap style={{ marginBottom: 20 }}>
							<b>Paramétrages commande</b>
						</Typography>

						<div>
							<FormControl className={classes.formControl} style={{ width : '50%', marginBottom: 15 }}>
								<InputLabel htmlFor='delivery_warehouse' shrink>Méthode de commande</InputLabel>
								<Select
									inputRef={register()}
									native
									inputProps={{
										name: 'order_method'
									}}
								>
									<option value=''></option>
									<option value='generic_form'>Formulaire générique</option>
									<option value='custom_form'>Formulaire personnalisé</option>
									<option value='edi'>EDI</option>
									<option value='manual'>Manuelle</option>
									<option value='script'>Script</option>
								</Select>
							</FormControl>
						</div>

						<div>
							<Controller
								control={control}
								name={'mail_new_order'}
								defaultValue={true}
								render={props =>
									<FormControlLabel
									control={<Checkbox color='default' onChange={(e) => props.onChange(e.target.checked)} checked={props.value} />}
									label="Envoi d'un email de confirmation"
									style={{ width: 400 }}
									/>
								}
							/>
						</div>

					</div>
					}

					{admin &&
					<div style={{ marginBottom: 40 }}>

						<Typography variant="h6" noWrap style={{ marginBottom: 20 }}>
							<b>Paramétrages livraison</b>
						</Typography>

						<div>
							<FormControl className={classes.formControl} style={{ width : '50%', marginBottom: 15 }}>
								<InputLabel htmlFor='delivery_warehouse' shrink>Livraison via plateforme</InputLabel>
								<Select
									inputRef={register()}
									native
									inputProps={{
										name: 'delivery_warehouse'
									}}
									onChange={(e) => setHasPlatform(e.target.value !== '')}
								>
									<option value=''>Non</option>
									{Object.values(shops).filter((element) => element.tags !== undefined && element.tags.filter((tag) => tag === 'warehouse').length > 0).map((row, index) => (
										<option key={row.id} value={row.id}>{row.name}</option>
									))}
								</Select>
							</FormControl>
						</div>

						<div style={{ display: hasPlatform ? 'none' : 'block' }}>

							<div style={{ display: 'flex', marginBottom: 15 }}>
								<FormControl className={classes.formControl} style={{ width : '50%' }}>
									<InputLabel htmlFor="container_type">Contenant</InputLabel>
									<Select
										inputRef={register()}
										native
										inputProps={{
											name: 'container_type',
											id: 'container_type',
										}}
									>
										{Object.entries(container_types).map((row, index) => (
											<option key={row[0]} value={row[0]}>{row[1].name}</option>
										))}
									</Select>
								</FormControl>
							</div>

							<div>
								<Controller
									control={control}
									name={'delivery_via_pallet'}
									defaultValue={false}
									render={props =>
										<FormControlLabel
										control={<Checkbox color='default' onChange={(e) => props.onChange(e.target.checked)} checked={props.value} />}
										label="Livraison via palette"
										style={{ width: 300 }}
										/>
									}
								/>
							</div>

							<div>
								<Controller
									control={control}
									name={'container_rotation'}
									defaultValue={false}
									render={props =>
										<FormControlLabel
										control={<Checkbox color='default' onChange={(e) => props.onChange(e.target.checked)} checked={props.value} />}
										label="Rotation des contenants"
										style={{ width: 300 }}
										/>
									}
								/>
							</div>

							<div style={{ display: 'flex' }}>

								<Controller
									control={control}
									name={'delivery_picture'}
									defaultValue={false}
									render={props =>
										<FormControlLabel
										control={<Checkbox color='default' onChange={e => props.onChange(e.target.checked)} checked={props.value} />}
										label="Photo"
										/>
									}
								/>

								<Controller
									control={control}
									name={'delivery_picture_library'}
									defaultValue={false}
									render={props =>
										<FormControlLabel
										control={<Checkbox color='default' onChange={(e) => props.onChange(e.target.checked)} checked={props.value} />}
										label="Bibliothèque Photos"
										/>
									}
								/>

								<Controller
									style={{ marginLeft : 10 }}
									control={control}
									name={'delivery_signature'}
									defaultValue={false}
									render={props =>
										<FormControlLabel
										control={<Checkbox color='default' onChange={(e) => props.onChange(e.target.checked)} checked={props.value} />}
										label="Signature"
										/>
									}
								/>

								<Controller
									style={{ marginLeft : 10 }}
									control={control}
									name={'delivery_unsold'}
									defaultValue={false}
									render={props =>
										<FormControlLabel
										control={<Checkbox color='default' onChange={(e) => props.onChange(e.target.checked)} checked={props.value} />}
										label="Invendus"
										/>
									}
								/>

							</div>

							<div>
								<Controller
									control={control}
									name={'shelving'}
									defaultValue={false}
									render={(props) =>
										<FormControlLabel
										control={<Checkbox color='default' onChange={(e) => props.onChange(e.target.checked)} checked={props.value} />}
										label="Mise en rayon des produits"
										style={{ width: 500 }}
										/>
									}
								/>
							</div>

							<div style={{ display: 'flex', marginBottom: 15 }}>

								<FormControl>
									<TextField
										inputRef={register()}
										id={'preparation_hour'}
										name={'preparation_hour'}
										label="Heure limite de préparation"
										type="time"
										defaultValue='19:00'
										InputLabelProps={{
											shrink: true,
										}}
										inputProps={{
											step: 300
										}}
										style={{ width: 200 }}
									/>
								</FormControl>

								<FormControl style={{ marginLeft: 10 }}>
									<TextField
										inputRef={register()}
										id={'delivery_hour'}
										name={'delivery_hour'}
										label="Heure limite chez le client"
										type="time"
										defaultValue='10:00'
										InputLabelProps={{
											shrink: true,
										}}
										inputProps={{
											step: 300
										}}
										style={{ width: 200 }}
									/>
								</FormControl>
							</div>

							<div>
								<Controller
									control={control}
									name={'delivery_national'}
									defaultValue={false}
									render={props =>
										<FormControlLabel
										control={<Checkbox color='default' onChange={(e) => {
											props.onChange(e.target.checked);
											return true;
										}} checked={props.value} />}
										label="Livraison nationale"
										style={{ width: 250, marginRight: 10 }}
										/>
									}
								/>

								<Controller
									control={control}
									name={'delivery_national_direct'}
									defaultValue={false}
									render={props =>
										<FormControlLabel
										disabled={!display_national_direct}
										control={<Checkbox color='default' onChange={(e) => props.onChange(e.target.checked)} checked={props.value} />}
										label="Livraison plateforme uniquement"
										style={{ width: 350 }}
										/>
									}
								/>
							</div>

							<div>
								<Controller
									control={control}
									name={'delivery_note_before'}
									defaultValue={false}
									render={props =>
										<FormControlLabel
										control={<Checkbox color='default' onChange={e => props.onChange(e.target.checked)} checked={props.value} />}
										label="Envoyer le bon de livraison en amont de la livraison"
										style={{ width: 500 }}
										/>
									}
								/>
							</div>

							<div>
								<Controller
									control={control}
									name={'delivery_costed'}
									defaultValue={false}
									render={props =>
										<FormControlLabel
										control={<Checkbox color='default' onChange={e => props.onChange(e.target.checked)} checked={props.value} />}
										label="Bon de livraison chiffré"
										style={{ width: 500 }}
										/>
									}
								/>
							</div>
						</div>

						<div style={{ marginBottom: 40, display: hasPlatform ? 'none' : 'block' }}>

							<Typography noWrap style={{ marginBottom: 20 }}>
								<b>Horaires de livraison</b>
							</Typography>

							{WeekDays.map((day, index) => (
							<div key={'week_days_' + index} style={{ display: 'flex', marginBottom: 15 }}>

								<Controller
									name={`delivery_days[${index}].enabled`}
									control={control}
									color='default'
									defaultValue={false}
									render={props =>
										<FormControlLabel
										control={<Checkbox color='default' onChange={(e) => props.onChange(e.target.checked)} checked={props.value} />}
										label={day}
										style={{ width: 170 }}
										/>
									}
								/>

								<TextField
									inputRef={register()}
									name={`delivery_days[${index}].hour`}
									label="heure"
									type="time"
									defaultValue="07:00"
									InputLabelProps={{
										shrink: true,
									}}
									inputProps={{
										step: 300
									}}
									style={{ width: 100, marginLeft: 10 }}
								/>

							</div>
							))}
						</div>

					</div>
					}

					{(admin) &&
					<div style={{ marginBottom: 40, display: hasPlatform ? 'none' : 'block' }}>

						<Typography variant="h6" noWrap style={{ marginBottom: 20 }}>
							<b>Paramétrages facturation</b>
						</Typography>

						<div style={{ display: 'flex', paddingBottom: 15 }}>
							<FormControl className={classes.formControl} style={{ width: 200 }}>
								<InputLabel htmlFor='invoicing_holding' shrink>Holding de facturation</InputLabel>
								<Select
									inputRef={register()}
									native
									onChange={(e) => {
										current_shop.invoicing.holding = e.target.value;
										setCurrentShop({...current_shop});
									}}
									inputProps={{
										name: 'invoicing.holding'
									}}
								>
									<option value=''></option>
									{Object.values(shops).sort((a, b) => {return a.name > b.name ? 1 : -1}).map((row, index) => (
										<option key={row.id} value={row.id}>{row.name}</option>
									))}
								</Select>
							</FormControl>
						</div>

						{!obsolete &&
						<div style={{ display: 'flex', paddingBottom: 15 }}>

							<FormControl className={classes.formControl} style={{ width: 200 }}>
								<InputLabel htmlFor='invoicing_type' shrink>Type de facturation</InputLabel>
								<Select
									inputRef={register()}
									native
									inputProps={{
										name: 'invoicing.type',
										id: 'invoicing_type',
									}}
								>
									<option value='invoice_order'>Facture / commande</option>
									<option value='invoice_month'>Facture mensuelle</option>
									<option value='invoice_revshare'>Concession</option>
									<option value='no_invoice'>Pas de facturation</option>
								</Select>
							</FormControl>

							<TextField
								inputRef={register({})}
								InputProps={{
									startAdornment : <InputAdornment position="start">%</InputAdornment>,
								}}
								id='invoicing_percentage'
								name='invoicing.percentage'
								label='Pourcentage'
								style={{ width: 200, marginLeft: 10 }}
							/>
						</div>
						}

						{(current_shop.invoicing === undefined || current_shop.invoicing.holding === undefined || current_shop.invoicing.holding === '') &&
						<div>
							<div style={{display: 'flex', paddingBottom: 15 }}>

								<FormControl className={classes.formControl} style={{ width: 200 }}>
									<InputLabel htmlFor='invoicing_frequency' shrink>Fréquence de facturation</InputLabel>
									<Select
										inputRef={register()}
										native
										inputProps={{
											name: 'invoicing.frequency',
											id: 'invoicing_frequency',
										}}
									>
										<option value='order'>Facture à la commande</option>
										<option value='weekly'>Facture hebdomadaire</option>
										<option value='monthly'>Facture mensuelle</option>
										<option value='no_invoice'>Pas de facturation</option>
									</Select>
								</FormControl>

								<FormControl className={classes.formControl} style={{ width : 200, marginLeft: 10 }}>
									<InputLabel htmlFor='payment_period' shrink>Délai de paiement</InputLabel>
									<Select
										inputRef={register()}
										native
										inputProps={{
											name: 'invoicing.payment_period',
											id: 'invoicing_payment_period',
										}}
									>
										<option value='0'>A réception</option>
										<option value='10'>10 jours</option>
										<option value='30'>30 jours</option>
										<option value='45'>45 jours</option>
									</Select>
								</FormControl>

							</div>

							<div style={{ display: 'flex', paddingBottom: 15 }}>

								<TextField
									inputRef={register({})}
									name='invoicing.provider_code'
									label='Code Fournisseur'
									InputLabelProps={{
										shrink: true,
									}}
									style={{ width: 200 }}
								/>

								<TextField
									inputRef={register({})}
									name='invoicing.gold_code'
									label='Code Gold'
									InputLabelProps={{
										shrink: true,
									}}
									style={{ width: 200, marginLeft: 10 }}
								/>

							</div>

							{!obsolete &&
							<div style={{display: 'flex', marginBottom : 15}}>
								<TextField
									inputRef={register({

									})}
									InputProps={{
										startAdornment: <InputAdornment position="start">€</InputAdornment>,
									}}
									id='invoicing_franco'
									name='invoicing.franco'
									label='Franco de port'
									type='number'
									style={{width: 200}}
									error={(errors.invoicing_franco !== undefined && errors.invoicing_franco) ? true : false}
								/>
							</div>
							}

							<div style={{ display: 'flex', marginBottom: 15 }}>
								<FormControl className={classes.formControl} style={{ width: 200 }}>
									<InputLabel htmlFor='pricelist_group_id' shrink>Groupe de prix associé</InputLabel>
									<Select
										inputRef={register()}
										native
										inputProps={{
											name: 'pricelist_group_id',
											id: 'pricelist_group_id',
										}}
									>
										<option value=''>---</option>
										{Object.values(price_list_groups).map((row, index) => (
											<option key={'pricelist_group_id_' + index} value={row.id}>{row.name}</option>
										))}
									</Select>
								</FormControl>
							</div>

							<div>
								<Controller
									style={{ marginLeft: 5 }}
									control={control}
									name={'invoicing.factoring'}
									defaultValue={false}
									render={props =>
										<FormControlLabel
											control={<Checkbox color='default' onChange={e => props.onChange(e.target.checked)} checked={props.value} />}
											label="Activer l'affacturage"
											style={{ width: 300 }}
										/>
									}
								/>
							</div>

							<div>
								<Controller
									control={control}
									name={'invoicing.postal'}
									defaultValue={false}
									render={props =>
										<FormControlLabel
											control={<Checkbox color='default' onChange={e => props.onChange(e.target.checked)} checked={props.value} />}
											label="Envoyer la facture par courrier"
											style={{ width: 300 }}
										/>
									}
								/>
							</div>
						</div>
						}

					</div>
					}

					<div style={{ position: 'fixed', bottom: 0, left: 200, width: '100%', backgroundColor: 'white', padding: 24, borderTop: 'solid rgba(0, 0, 0, 0.12) 1px' }}>
						<Button disabled={!checkUserRights('shops_write')} variant='contained' color="default" type='submit'>
							Enregistrer
						</Button>
					</div>

				</form>

			</div>
			}

			{(admin && action === 'update' && !hasPlatform) &&
			<div id='tab_2' role='tabpanel' hidden={tab_value !== 2}>
				<Invoices include={true} shop={current_shop} />
			</div>
			}

			{(admin && action === 'lala') &&
			<div id='tab_3' role='tabpanel' hidden={tab_value !== 3}>
				<Sales include={true} shop_id={current_shop.id} />
			</div>
			}

			{(admin && action === 'update') &&
			<div role='tabpanel' hidden={tab_value !== 3}>
				<ProductionTemplate include={true} shop={current_shop} pricelist_group_id={current_shop.pricelist_group_id} id={current_shop.production_template_id === undefined ? 'new' : current_shop.production_template_id} />
			</div>
			}

		</div>
	);
}

export default React.memo(Shop);

const useStyles = makeStyles({
	table: {
		minWidth: 700,
	}
});
