import React from 'react';
import { useState, useEffect, useContext } from 'react';

import { withStyles, makeStyles } from '@material-ui/core/styles';
import { CircularProgress, Table, TableBody, TableCell, TablePagination, TableSortLabel, TableContainer, TableHead, TableRow, Paper, Typography, Breadcrumbs, Button, Avatar, TextField, Select, InputLabel, FormControl, RadioGroup, FormControlLabel, Radio, FormLabel } from '@material-ui/core';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { Link } from "react-router-dom";

import DeleteIcon from '@material-ui/icons/Delete';
import FileCopyIcon from '@material-ui/icons/FileCopy';

import { DeleteProduct } from '.././Store';
import { GetImagePath } from '.././Utils';

import { ShakiContext } from "../store/ShakiProvider";
import { ShopsContext } from "../store/ShopsProvider";
import { UsersContext } from "../store/UsersProvider";


function Products() {

	const { checkUserRights } = useContext(UsersContext);
	const { products, products_by_sku, getRemoteConf, getArticlesByCategory, createProductAlias } = useContext(ShakiContext);
	const { price_list } = useContext(ShopsContext);


	const title = 'Produits';
	const classes = useStyles();


	const [filter, setFilter] = useState(sessionStorage.getItem('filter_products') !== null ? sessionStorage.getItem('filter_products') : '');
	const [filter_rate_card, setFilterRateCard] = useState('');
	const [list, setList] = useState([]);
	const [sort, setSort] = useState('asc');
	const [orderBy, setOrderBy] = useState('sku');
	const [packaging_types, setPackagingTypes] = useState({});
	const [articles, setArticles] = useState({});

	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(100);

	const [rate_cards, setRateCards] = useState([]);

	const [open_create_alias, setOpenCreateAlias] = useState(false);
	const [create_alias_obj, setCreateAliasObj] = useState({});
	const [other_value, setOtherValue] = useState('');
	const [loading, setLoading] = useState(false);
	const [create_alias_error, setCreateAliasError] = useState(null);


	useEffect(() => {

		var a = [];

		for (var i in products) {

			var product = products[i];

			if (product.sub_recipe) continue;
			if (product.disabled === true) continue;

			if (filter !== '' && product.search_index.toLowerCase().search(filter.toLowerCase()) === -1) continue;

			if (filter_rate_card !== '' && price_list[filter_rate_card] !== undefined && (price_list[filter_rate_card].prices[product.sku] === undefined || price_list[filter_rate_card].prices[product.sku] === 0)) continue;

			product.key_sort = (product.parent_sku === undefined ? product.sku : product.parent_sku) + (product.parent_sku === undefined ? 0 : 1)
			a.push(product);
		};

		setList(a.sort((a, b) => a.key_sort > b.key_sort ? 1 : -1));
		sessionStorage.setItem('filter_products', filter);

	}, [products, filter, filter_rate_card, price_list]);


	useEffect(() => {
		setPackagingTypes(getRemoteConf('packaging_types'));
	}, []);


	useEffect(() => {

		getArticlesByCategory('Emballage', (list) => {
			var hash = {};
			for (var article of list) {
				hash[article.id] = article;
			};
			setArticles(hash);
		});

	}, []);


	useEffect(() => {

		if (Object.keys(price_list).length === 0) return;

		const list = [];
		for (var price_list_id in price_list) {

			var p = price_list[price_list_id];
			if (p.rate_card === undefined || p.rate_card === false) continue;

			list.push(p);
		};

		setRateCards(list.sort((a, b) => a.date_start > b.date_start ? 1 : -1));

	}, [price_list]);


	const initCreateAlias = (parent_sku) => {

		setCreateAliasObj({
			parent_sku: parent_sku
		});
		setOpenCreateAlias(true);
	};


	const handleCreateAliasOtherChange = (e) => {

		setOtherValue(e.target.value.trim());

		if (create_alias_obj.radio === 'other') setCreateAliasObj((prev) => ({ ...prev, sku: e.target.value.trim() }));

	};

	const handleCreateAliasChange = (event) => {

		if (event.target.value === 'other') {
			var value = other_value;
			var radio = 'other';
		} else {
			var value = event.target.value;
			var radio = event.target.value;
		};

		return setCreateAliasObj((prev) => ({ ...prev, sku: value, radio: radio }));
	};


	const closeCreateAlias = () => {
		setOpenCreateAlias(false);
	};


	var del = (e) => {
		DeleteProduct(e);
	};

	console.log(create_alias_obj);

	return (
		<div>

			<div style={{ padding: 20 }}>

				<Breadcrumbs aria-label="breadcrumb">
					<Link to='/'>Dashboard</Link>
					<Typography>{title}</Typography>
				</Breadcrumbs>

				<h2>{title}</h2>

				<div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
					<div />
					<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'end', justifyContent: 'space-between' }}>
						<div style={{ marginRight: 20 }}>
							<FormControl style={{ width: 200 }} >
								<InputLabel>Filter par liste de prix</InputLabel>
								<Select
									native
									value={filter_rate_card}
									onChange={(e) => setFilterRateCard(e.target.value)}
								>
									<option value=''>--</option>
									{rate_cards.map((row, i) => (
										<option key={row.id} value={row.id}>{row.name}</option>
									))}
								</Select>
							</FormControl>
						</div>
						<div style={{ alignItems: 'center' }}>
							<TextField
								value={filter}
								style={{ width: 200 }}
								placeholder="Filtrer les produits"
								onChange={(e) => setFilter(e.target.value)}
							/>
						</div>
					</div>
				</div>
			</div>

			<TableContainer component={Paper}>
				<Table className={classes.table} aria-label="customized table">

					<TableHead>
						<TableRow>
							<StyledTableCell></StyledTableCell>
							<StyledTableCell><b>SKU</b></StyledTableCell>
							<StyledTableCell><b>EAN13</b></StyledTableCell>
							<StyledTableCell><b>Marque</b></StyledTableCell>
							<StyledTableCell><b>Nom</b></StyledTableCell>
							<StyledTableCell><b>Conditionnement</b></StyledTableCell>
							<StyledTableCell></StyledTableCell>
						</TableRow>
					</TableHead>

					<TableBody>

						{list.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => (

						<StyledTableRow key={row.id}>

							<StyledTableCell >
								<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', }} >
									{row.parent_sku !== undefined ?
										<div style={{ width: 30, height: 70, opacity: 0.5, marginRight: 2, borderRadius: 5 }} />
										:
										<div style={{ width: 5, height: 70, backgroundColor: '#AEEE95', opacity: 0.8, marginRight: 2, borderRadius: 5 }} />
									}
									<Avatar src={GetImagePath(row.images, 'main')} className={classes.large} style={{ width: 105, height: 70 }} variant='rounded' component='span'/>
								</div>
							</StyledTableCell>

							<StyledTableCell component="th" scope="row">

								<Link to={'/products/' + row.id}>
									{row.sku}
								</Link>

								<br />
								<div style={{ fontSize: 12 }}>{row.parent_sku}</div>

							</StyledTableCell>

							<StyledTableCell>{row.ean13}</StyledTableCell>

							<StyledTableCell>
								{row.disabled ? '🔴' : '' } {row.brand}
								<br/>
								<div style={{ fontSize: 12 }}>{row.category}</div>
							</StyledTableCell>

							<StyledTableCell>
								{row.name}<br />
								<div style={{ fontSize: 12 }}>{row.weight}g</div>
							</StyledTableCell>

							<StyledTableCell>
								{(Object.keys(articles).length > 0 && row.packaging !== undefined && row.packaging.articles !== undefined && row.packaging.articles.length > 0) &&
									<span>
										{row.packaging.articles.sort((a, b) => articles[a].name.localeCompare(articles[b].name)).map((article) => articles[article].name).join(', ')}
									</span>
								}<br/>
								{row.packaging !== undefined && row.packaging.format !== '' ? 'Avant : ' + row.packaging.format : '-'}<br/>
								{row.packaging !== undefined && row.packaging.back_format !== '' ? 'Arrière : ' + row.packaging.back_format : '-'}
							</StyledTableCell>

							<StyledTableCell>
								{row.parent_sku === undefined &&
								<Button disabled={!checkUserRights('products_create_alias')} onClick={(e) => initCreateAlias(row.sku)} color='primary' size='small' style={{ minWidth: 20 }}>
									<FileCopyIcon />
								</Button>
								}
							</StyledTableCell>

						</StyledTableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>

			<TablePagination
				rowsPerPageOptions={[50, 100, 200]}
				component="div"
				count={list.length}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={(event, newPage) => {
					setPage(newPage);
				}}
				onRowsPerPageChange={(event) => {
					setRowsPerPage(parseInt(event.target.value, 10));
					setPage(0);
				}}
			/>

			<Dialog
				open={open_create_alias}
				onClose={closeCreateAlias}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">Création d'un alias pour <b>{create_alias_obj.parent_sku}</b></DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						<>
						<RadioGroup aria-label="gender" name="gender1" value={create_alias_obj.radio} onChange={handleCreateAliasChange}>
							{['STD', 'NST', 'LCI'].map((alias) => {
								if (products_by_sku[create_alias_obj.parent_sku + '_' + alias] !== undefined) return null;
								return (
									<FormControlLabel key={alias} value={create_alias_obj.parent_sku + '_' + alias} control={<Radio />} label={create_alias_obj.parent_sku + '_' + alias} />
								)
							})}
							<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
							<FormControlLabel value={'other'} disabled={other_value === ''} control={<Radio />} label='' />
								<TextField
									label='Autre'
									onChange={handleCreateAliasOtherChange}
									InputLabelProps={{ shrink: true }}
									style={{ width: 140, marginRight: 10 }}
								/>
							</div>
						</RadioGroup>
						{create_alias_error &&
							<Alert style={{ marginTop: 10 }} severity="error">{create_alias_error}</Alert>
						}
						</>
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={closeCreateAlias} color="primary">
						Annuler
					</Button>
					{loading === 'create_alias' ?
						<CircularProgress size={20} />
						:
						<Button disabled={create_alias_obj.sku === undefined || create_alias_obj.sku === ''} onClick={() => {

							setLoading('create_alias');

							createProductAlias(create_alias_obj.parent_sku, create_alias_obj.sku, (response) => {
								setLoading(false);
								setCreateAliasError(null);
								closeCreateAlias();
							}, (error) => {
								setLoading(false);
								setCreateAliasError(error.message);
								console.log(error);
							});

						}} color="primary" autoFocus>
							Confirmer
						</Button>
					}
				</DialogActions>
			</Dialog>

		</div>
	);
};

export default React.memo(Products);

const StyledTableCell = withStyles((theme) => ({
	head : {
		backgroundColor : theme.palette.common.black,
		color : theme.palette.common.white,
	},
	body : {
		fontSize: 14,
	},
}))(TableCell);

const StyledTableSortLabel = withStyles((theme) => ({
	root : {
		'&:nth-of-type(odd)': {
			backgroundColor: theme.palette.action.hover,
		},
	},
}))(TableSortLabel);

const StyledTableRow = withStyles((theme) => ({
	root : {
		'&:nth-of-type(odd)': {
			backgroundColor: theme.palette.action.hover,
		},
	},
}))(TableRow);

const useStyles = makeStyles({
	table: {
		minWidth: 700,
	},
	large: {
		width: 80,
		height: 80,
	},
	visuallyHidden: {
		border: 0,
		clip: 'rect(0 0 0 0)',
		height: 1,
		margin: -1,
		overflow: 'hidden',
		padding: 0,
		position: 'absolute',
		top: 20,
		width: 1,
	},
});
