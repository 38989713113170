import firebase from "firebase/compat/app";
import "firebase/compat/firestore"
import "firebase/compat/auth";

import { useState } from 'react';
import { TextField, Button, Paper } from '@material-ui/core';
import { useParams, useLocation } from "react-router-dom";

export function SignIn() {

	firebase.auth().useDeviceLanguage();


	var _email = new URLSearchParams(useLocation().search).get('email');
	if (_email !== null) _email = _email.replace(' ', '+');
	var _password = new URLSearchParams(useLocation().search).get('password');
	var domain_name = window.location.hostname;

	const [confirm, setConfirm] = useState(null);
	const [code, setCode] = useState('');
	const [phone_number, setPhoneNumber] = useState('+33');
	const [email, setEmail] = useState(_email !== null ? _email : '');
	const [password, setPassword] = useState(_password !== null ? _password : '');


	var auth = () => {

		setupRecapcha();

		let appVerifier = window.recaptchaVerifier;
		firebase.auth().signInWithPhoneNumber(phone_number, appVerifier).then((confirmationResult) => {
			setConfirm(confirmationResult);
		}).catch((error) => {
			console.log('error', error)
		});
	};


	var emailPasswordAuth = () => {

		setupRecapcha();
		let appVerifier = window.recaptchaVerifier;

		firebase.auth().signInWithEmailAndPassword(email, password).then(function (confirmationResult) {

		}).catch(function (error) {

		});
	};


	var setupRecapcha = () => {

		window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha', {
			size: 'invisible',
			callback: (response) => {
				console.log('resolved', response)
				//auth();
			}
		});
	};


	function confirmCode() {

		confirm.confirm(code).then(function (result) {
			console.log('auth', result)
		}).catch(function (error) {

		});
	};


	if (!confirm) {
		return (
			<>
			<div id="recaptcha" />
			<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: 40 }}>

				<h2>Identification</h2>
				{(domain_name === 'admin.lunafood.com' || domain_name === 'localhost') &&
				<Paper style={{ width: 400, padding: 20, marginBottom: 20 }}>

					<div style={{ marginBottom : 20 }}>
						<TextField
							style={{width: '100%'}}
							id='phone_number'
							name='phone_number'
							value={phone_number}
							label='Votre numéro de téléphone'
							onChange={(e) => setPhoneNumber(e.target.value)}
						/>
					</div>

					<div>
						<Button type='submit' variant='contained' color='primary' onClick={() => auth()}>
							Connexion
						</Button>
					</div>

				</Paper>
				}

				<Paper style={{ width: 400, padding: 20 }}>
					<div style={{ marginBottom : 20 }}>
						<TextField
							style={{ width: '100%' }}
							id='email'
							name='email'
							value={email}
							label='Email'
							onChange={e => setEmail(e.target.value)}
						/>
					</div>
					<div style={{marginBottom : 20}}>
						<TextField
							style={{width: '100%'}}
							id='password'
							name='password'
							type='password'
							value={password}
							label='Mot de passe'
							onChange={e => setPassword(e.target.value)}
						/>
					</div>
					<div>
						<Button id='loginButton' type='submit' variant='contained' color='primary' onClick={() => emailPasswordAuth()}>
							Valider
						</Button>
					</div>
				</Paper>

			</div>
			</>
		);
	};

	return (
		<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 40 }}>

			<Paper style={{width: 400, padding: 20}}>
				<h2>Identificationnnn</h2>
				<div style={{marginBottom : 20}}>
					<TextField
						style={{width: '100%'}}
						id='code'
						name='code'
						value={code}
						label='Code de vérification'
						onChange={e => setCode(e.target.value)}
					/>
				</div>
				<div>
					<Button type='submit' variant='contained' color='primary' onClick={() => confirmCode()}>
						Valider le code
					</Button>
				</div>
			</Paper>

		</div>
	);
}
